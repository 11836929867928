import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Container,
  Header,
  Label,
  Loader,
} from 'semantic-ui-react'
import ScholarshipApplicationAdmin from './scholarship_application_admin'
import ScholarshipApplicationTable from './scholarship_application_table'
import ScholarshipBreadcrumb from './breadcrumb'
const csrfToken = document.getElementsByName('csrf-token')[0].content

function ScholarshipsShow() {
  const [applications, setApplications] = useState([])
  const [loading, toggleLoading] = useState(true)
  const [scholarship, setScholarship] = useState(null)
  const [modal, toggleModal] = useState(null)

  const fetchData = useCallback(async (recordId) => {
    try {
      const response = await axios.get(`/api/v1/scholarships/${recordId}`)
      console.log('response', response)
      setApplications(response.data.applications)
      setScholarship(response.data.scholarship)
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    } finally {
      toggleLoading(false)
    }
  })

  const openModal = (record) => toggleModal(record)
  const closeModal = () => {
    fetchData(scholarship._id.$oid)
    toggleModal(null)
  }

  const updateStatus = async (applicationId, applicationStatus) => {
    try {
      const response = await axios.put(
        `/api/v1/scholarship_applications/${applicationId}/status`,
        { scholarship_application: { status: applicationStatus }},
        {
          headers: {
            'X-CSRF-TOKEN': csrfToken,
          }
        }
      )
      toggleModal(response.data.data)
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    }
  }

  useEffect(() => {
    const currentPath = window.location.pathname
    const pathArr = currentPath.split('/').filter((p) => p.trim() !== '')
    fetchData(pathArr.pop())
  }, [])

  if (loading) {
    return (<Loader active />)
  }
  return (
    <div id="scholarships-show" className="aep-admin__container">
      <Container>
        <div className="aep-admin__header">
          <ScholarshipBreadcrumb record={scholarship} />
          <Header as="h1">{scholarship.name}</Header>
        </div>
        <div className="aep-admin__content">
          <ScholarshipApplicationTable
            data={applications}
            onClick={openModal}
          />
        </div>
      </Container>
      {modal !== null && (
        <ScholarshipApplicationAdmin
          open={modal !== null}
          data={modal}
          scholarship={scholarship}
          onClose={closeModal}
          onSubmit={updateStatus}
        />
      )}
    </div>
  )
}

export default ScholarshipsShow
