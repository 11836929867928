import axios from 'axios'
import React, { useState, useEffect, useCallback } from 'react'
import { Button, Form, Icon, Message, } from 'semantic-ui-react'
import { randomKeyString } from '../utils'
import { cvMimeTypes } from '../constants'

const csrfToken = document.getElementsByName('csrf-token')[0].content

function CVUploader() {
  const [selectedCv, setCvFile] = useState(null)
  const [uploadedCv, setUploadedCv] = useState(null)
  const [cvKey, setCvKey] = useState('')
  const [isUploading, toggleUploading] = useState(false)
  const [isLoading, toggleLoading] = useState(true)
  const [cvError, setCvError] = useState(null)

  const getUploadedCv = useCallback(async () => {
    try {
      const response = await axios.get('/api/v1/cv')
      console.log('response', response)
      setUploadedCv(response.data)
    } catch (err) {
      console.error('err', err)
      console.error('err', err.stack)
    } finally {
      toggleLoading(false)
    }
  }, [uploadedCv])

  useEffect(() => {
    getUploadedCv()
  }, [])

  const selectCV = (e) => {
    console.log('e.target.name', e.target.name)
    console.log('e.target.files', e.target.files)
    if (e.target.files.length === 0) {
      setCvFile(null)
      setCvKey(randomKeyString())
      return
    }

    if (cvMimeTypes.indexOf(e.target.files[0].type) === -1) {
      setCvError('Only PDF or Word Documents will be accepted.')
    } else {
      setCvError(null)
    }

    setCvFile(e.target.files[0])
}

  const uploadCv = async () => {
    try {
      toggleUploading(true)
      const formData = new FormData()
      formData.append('cv[files][]', selectedCv)
      const response = await axios.post('/api/v1/cv',
        formData, {
          headers: {
            'X-CSRF-TOKEN': csrfToken,
          }
      })
      setUploadedCv(response.data)
      setCvFile(null)
      setCvKey(randomKeyString())
    } catch (err) {
      const { error } = err.response.data
      setCvError(error)
      console.error('err', err)
      console.error('err.stack', err.stack)
    } finally {
      toggleUploading(false)
    }
  }

  const resetCV = () => {
    setCvFile(null)
    setCvKey(randomKeyString())
  }

  if (isLoading) {
    return null
  }

  return (
    <div className="cv-uploader" style={{ marginBottom: '1rem' }}>
      <Form>
        <Form.Field required={!uploadedCv}>
        <label>CV</label>
        {!uploadedCv && (
          <Message color="yellow">
            <p>Please upload your CV to complete your profile. You will not be able to apply for AEP scholarships or restricted data without one on file.</p>
          </Message>
        )}
        {cvError && (
          <Message color="red">
            <p>{cvError}</p>
          </Message>
        )}
        {uploadedCv && (
          <div className="uploaded-cv">
            <Icon name='file' />
            <span className="cv filename">
              {uploadedCv.filename}
            </span>
            <span className="cv upload-date">
              {uploadedCv.upload_date}
            </span>
          </div>
        )}
        <input
          type="file"
          onChange={(e) => selectCV(e)}
          key={cvKey}
        />

        {selectedCv && (
          <div className="upload-op" style={{margin: '1rem 0'}}>
            <Button onClick={uploadCv} disabled={isUploading || cvError}>
              Upload CV
            </Button>
            <Button onClick={resetCV}>
              Reset
            </Button>
          </div>
        )}
        </Form.Field>
      </Form>
    </div>
  )
}

export default CVUploader
