import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Button,
  Checkbox,
  Form,
  Modal
} from 'semantic-ui-react'
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/code';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/help';
import 'tinymce/plugins/wordcount';


import { Editor } from '@tinymce/tinymce-react'

const helpStyle = {
  color: '#666',
  fontSize: '0.9rem',
  fontWeight: 'normal',
}

const lastUpdateStyle = {
  ...helpStyle,
  float: 'right',
}

const editorInit = {
  height: 500,
  menubar: false,
  plugins: [
    'advlist lists link charmap preview anchor code',
    'searchreplace visualblocks',
    'insertdatetime table paste help wordcount'
  ],
  toolbar:
    'undo redo | formatselect | bold italic backcolor | \
    alignleft aligncenter alignright alignjustify | \
    bullist numlist outdent indent | removeformat | help'
}

function ScholarshipsModal({ data, open, onClose, onSubmit }) {
  const [name, updateName] = useState('')
  const [description, updateDescription] = useState('')
  const [active, toggleActive] = useState(false)
  const [accept, toggleAccept] = useState(false)

  const handleNameChange = (e, { value }) => updateName(value)
  const handleEditorChange = (content, editor) => {
    updateDescription(content)
  }

  const handleSubmit = () => {
    const formData = {
      name,
      description,
      active,
      accepting_applications: accept,
    }

    if (data) {
      onSubmit(data._id.$oid, { scholarship: formData })
    } else {
      onSubmit({ scholarship: formData })
    }
  }

  useEffect(() => {
    if (data) {
      updateName(data.name)
      updateDescription(data.description)
      toggleActive(data.active)
      toggleAccept(data.accepting_applications)
    }
  }, [])

  const disableSave = name.trim() === '' || description.trim() === ''

  return (
    <Modal
      centered={false}
      open={open}
      onClose={onClose}
    >
      <Modal.Header>
        {data && (
          <span style={lastUpdateStyle}>
            Last updated {moment(data.updated_at).format('MM/DD/YYYY')}
          </span>
        )}
        {data
          ? `Edit ${data.name}`
          : 'Create scholarship'
        }
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Name</label>
            <Form.Input
              type="text"
              onChange={handleNameChange}
              value={name}
            />
          </Form.Field>
          <Form.Field>
            <label>Description</label>
            <Editor
              init={editorInit}
              onEditorChange={handleEditorChange}
              value={description}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Active"
              onChange={() => toggleActive(!active)}
              checked={active}
            />
            <br />
            <span style={helpStyle}>
              Scholarships that are active will be viewable by users.
            </span>
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Accepting applications"
              onChange={() => toggleAccept(!accept)}
              checked={accept}
            />
            <br />
            <span style={helpStyle}>
              Toggle this field to indicate whether or not scholarships are accepted at this time.
            </span>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="green"
          disabled={disableSave}
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ScholarshipsModal