import axios from 'axios'
import React, { useCallback, useEffect, useState, } from 'react'
import {
  Button,
  Confirm,
  Container,
  Header,
  Icon,
  Message,
} from 'semantic-ui-react'
import ScholarshipsModal from './scholarships_modal'
import ScholarshipsTable from './scholarships_table'

const csrfToken = document.getElementsByName('csrf-token')[0].content

function Scholarships() {
  const [loading, toggleLoading] = useState(true)
  const [scholarships, setScholarships] = useState([])
  const [modal, toggleModal] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [confirmOp, toggleConfirmOp] = useState(null)

  const openModal = (data) => {
    if (data) {
      setModalData(data)
    }
    toggleModal(true)
  }

  const closeModal = () => {
    toggleModal(false)
    setModalData(null)
  }

  const fetchScholarships = async () => {
    try {
      const response = await axios.get('/api/v1/scholarships')
      console.log('response', response)
      setScholarships(response.data.data)
    } catch {
      console.error('err', err)
      console.error('err.stack', err.stack)
    } finally {
      toggleLoading(false)
    }
  }

  const updateScholarships = async (recordId, formData) => {
    try {
      const response = await axios.put(`
        /api/v1/scholarships/${recordId}`,
        formData,
        {
          headers: {
            'X-CSRF-TOKEN': csrfToken,
          }
       }
      )
      console.log('response', response)
      setScholarships(response.data.data)
      closeModal()
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    }
  }

  const createScholarships = async (formData) => {
    try {
      const response = await axios.post('/api/v1/scholarships', formData, {
        headers: {
          'X-CSRF-TOKEN': csrfToken,
        }
      })
      console.log('response', response)
      setScholarships(response.data.data)
      closeModal()
    } catch {
      console.error('err', err)
      console.error('err.stack', err.stack)
    }
  }

  const confirmDelete = (recordId) => {
    toggleConfirmOp(recordId)
  }

  const removeScholarship = async (recordId) => {
    try {
      const response = await axios.delete(`
        /api/v1/scholarships/${recordId}`,
        {
          headers: {
            'X-CSRF-TOKEN': csrfToken,
          }
       }
      )
      console.log('response', response)
      setScholarships(response.data.data)
      toggleConfirmOp(null)
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    }
  }

  useEffect(() => {
    fetchScholarships()
  }, [])

  const activeScholarships = scholarships.filter((record) => {
    return record.active
  })

  return (
    <div id="scholarships" className="aep-admin__container">
      <Container>
        <div className="aep-admin__header">
          <Button
            floated="right"
            icon
            labelPosition='left'
            style={{ 'marginBottom': '1em' }}
            onClick={() => openModal()}
          >
            <Icon name='book' />
            Add Scholarship
          </Button>
          <Header as="h1">Scholarships</Header>
        </div>
        <div className="aep-admin__content">
          {!loading && activeScholarships.length > 1 && (
            <Message warning>
              <Icon name="warning" />
              Currently, more than one scholarship is marked active. Please make sure that the one that is intended to be used is the <strong>only</strong> one marked active.
            </Message>
          )}
          {!loading && (
            <ScholarshipsTable
              data={scholarships}
              onEdit={openModal}
              onDelete={confirmDelete}
            />
          )}
        </div>
      </Container>
      {modal && (
        <ScholarshipsModal
          data={modalData}
          open={modal}
          onClose={closeModal}
          onSubmit={modalData
            ? updateScholarships
            : createScholarships}
        />
      )}
      {confirmOp && (
        <Confirm
          className="remove-scholarship-confirm"
          open={confirmOp !== null}
          content="Are you sure you want to remove this scholarship?"
          onConfirm={() => removeScholarship(confirmOp)}
          onCancel={() => toggleConfirmOp(null)}
          size="mini"
        />
      )}
    </div>
  )
}

export default Scholarships