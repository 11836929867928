import React, { Component } from 'react'
import { Table } from 'semantic-ui-react'

const AdminRow = (props) => (
  <Table.Row>
    <Table.Cell>
      <a href={`/admin/${props.admin._id.$oid}/edit`}>
        {`${props.admin.first_name} ${props.admin.last_name}`}
      </a>
    </Table.Cell>
    <Table.Cell>{props.admin.email}</Table.Cell>
    <Table.Cell>{props.admin.created_at}</Table.Cell>
  </Table.Row>
)

export default AdminRow
