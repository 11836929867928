import React from 'react'
import { Divider, List, } from 'semantic-ui-react'

const RestrictedApplicationSubmit = ({ data }) => {
  const colleaguesString = data.colleagueOrder.map((key) => {
    return data.colleagues[key]
  }).filter((colleague) => {
    return colleague.trim() !== ''
  }).join(', ')

  const colleagueItem = colleaguesString && (
    <List.Item>
      <List.Header>Colleagues</List.Header>
      <List.Description>{colleaguesString}</List.Description>
    </List.Item>
  )
  return (
    <div className="restricted-application">
      <p>Please review your application before submitting:</p>
      <Divider />
      <div className="restricted-application-review">
        <List>
          <List.Item>
            <List.Header>Summary of research </List.Header>
            <List.Description>{data.summary}</List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Justification for restricted data </List.Header>
            <List.Description>{data.justification}</List.Description>
          </List.Item>
          {colleagueItem}
          <List.Item>
            <List.Header>How will you securely maintain the data and prevent unauthorized access?</List.Header>
            <List.Description>{data.security_statement}</List.Description>
          </List.Item>
        </List>
      </div>
      <Divider />
      <p><strong>Please note, if your application is accepted you will need to provide evidence of IRB approval or exemption.</strong></p>
      <p>
        Thank you for your interest in using AEP data. Click the “Submit” button below to complete your application.
      </p>
    </div>
  )
}

export default RestrictedApplicationSubmit