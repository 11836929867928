import React, { Component } from 'react'
import {
  Form,
  Icon,
  List,
  Segment,
}
from 'semantic-ui-react'

class StrongPassword extends Component {
  state = { password: '', password_confirmation: '' }

  handleChange = (e, value, name) => {
    this.setState({
      [name]: value
    })
  }

  render() {
    const { password, password_confirmation } = this.state

    const characterLength = password.length >= 8
    const characterUpcase = password.match(/[A-Z]/)
    const characterDowncase = password.match(/[a-z]/)
    const characterNumber = password.match(/[0-9]/)
    const characterSpecial = password.match(/[!@#\$%\^&\*\(\)]/)

    const passwordsMatch = password.trim() !== '' && password === password_confirmation ? true : false

    const canConfirm = !(
      characterLength &&
      characterUpcase &&
      characterDowncase &&
      characterNumber &&
      characterSpecial &&
      passwordsMatch
    )

    return (
      <div id='strong-password-fields'>
        <Form.Field>
          <label>Password:</label>
          <Form.Input
            onChange={(e, {value}) => this.handleChange(e, value, 'password')}
            name="admin[password]"
            type="password"
            value={password}
          />
        </Form.Field>
        <Form.Field>
          <label>Password Confirmation:</label>
          <Form.Input
            onChange={(e, {value}) => this.handleChange(e, value, 'password_confirmation')}
            name="admin[password_confirmation]"
            type="password"
            value={password_confirmation}
          />
        </Form.Field>

        <Segment
          basic
          style={{ padding: '1.5em' }}
          textAlign="left"
        >
          <List>
            <List.Item>
              <List.Icon name={characterLength ? 'check' : 'cancel'} />
              At least 8 characters
            </List.Item>
            <List.Item>
              <List.Icon name={characterUpcase ? 'check' : 'cancel'} />
              At least one uppercase letter "A-Z"
            </List.Item>
            <List.Item>
              <List.Icon name={characterDowncase ? 'check' : 'cancel'} />
              At least one lowercase letter "a-z"
            </List.Item>
            <List.Item>
              <List.Icon name={characterNumber ? 'check' : 'cancel'} />
              At least one number
            </List.Item>
            <List.Item>
              <List.Icon name={characterSpecial ? 'check' : 'cancel'} />
              At least one special character (e.g., #, ^, -)
            </List.Item>
            <List.Item>
              <List.Icon name={passwordsMatch ? 'check' : 'cancel'} />
              Password and password confirmation match
            </List.Item>
          </List>
        </Segment>

        <Form.Field>
          <Form.Button
            disabled={canConfirm}
            fluid
            type="submit">
            Confirm
          </Form.Button>
        </Form.Field>
      </div>
    )
  }
}

export default StrongPassword
