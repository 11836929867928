import axios from 'axios'
import React, { useState, useEffect, useCallback } from 'react'
import get from 'lodash/get'
import {
  Accordion,
  Breadcrumb,
  Container,
  Header,
  Icon,
  List,
} from 'semantic-ui-react'

import RestrictedApplicationTable from './restricted_application_table'
import RestrictedApplicationAdmin from '../restricted_application_admin'

const RestrictedDataShow = ({ record, applications }) => {
  const [showInformation, toggleInformation] = useState(false)
  const [openModal, toggleModal] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [modalUser, setModalUser] = useState(null)
  const handleToggle = () => { toggleInformation(!showInformation)}
  const [currentApplications, setApplications] = useState(null)

  useEffect(() => {
    if (applications) {
      setApplications(applications)
    }
  }, [])

  const fetchApplication = async (recordId) => {
    try {
      const response = await axios.get(
        `/api/v1/restricted_applications/${recordId}`
      )
      console.log('response', response)
      setModalData(response.data.application)
      setModalUser(response.data.user)
      toggleModal(true)
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    }
  }

  const refreshRestrictedData = async () => {
    try {
      const response = await axios.get(
        `/restricted_data/${record._id.$oid}.json`
      )
      console.log('response', response)
      setApplications(response.data.applications)
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    }
  }

  const closeModal = () => {
    toggleModal(false)
    setModalData(null)
    setModalUser(null)
    refreshRestrictedData()
  }

  const applicationData = get(currentApplications, 'applications', [])
  const submittedApplications = applicationData.filter((record) => {
    return record.submitted
  })

  return (
    <div className="restricted-data-show">
      <div id="restricted-data" className="aep-admin__container">
        <Container>
          <div className="aep-admin__header">
            <Breadcrumb>
              <Breadcrumb.Section as="a" href="/">Home</Breadcrumb.Section>
              <Breadcrumb.Divider />
              <Breadcrumb.Section as="a" href="/restricted_data">
                Restricted Data
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
              <Breadcrumb.Section active>{record.name}</Breadcrumb.Section>
            </Breadcrumb>
          </div>

          <div className="aep-admin--restricted-data">
            <div className="restricted-data--information">
              <Accordion fluid styled>
                <Accordion.Title
                  active={showInformation}
                  onClick={handleToggle}
                >
                  <Icon name='dropdown' />
                  Display details
                </Accordion.Title>
                <Accordion.Content active={showInformation}>
                  <List>
                    <List.Item>
                      <List.Content>
                        <List.Header>Description</List.Header>
                        <p>
                          {record.description}
                        </p>
                      </List.Content>
                    </List.Item>
                    {record.topics
                    ? (
                      <List.Item>
                        <List.Content>
                          <List.Header>Topics</List.Header>
                          <p>{record.topics.join(' ')}</p>
                        </List.Content>
                      </List.Item>
                    )
                    : null
                    }
                    {record.keywords
                    ? (
                      <List.Item>
                        <List.Content>
                          <List.Header>Keywords</List.Header>
                          <p>{record.keywords.join(' ')}</p>
                        </List.Content>
                      </List.Item>
                    )
                    : null
                    }
                  </List>
                </Accordion.Content>
              </Accordion>
            </div>
            <div
              className="restricted-data--applications"
              style={{ padding: '1.5rem 0'}}
            >
              <Header as="h3">Applications</Header>
              <RestrictedApplicationTable
                data={submittedApplications}
                onClick={fetchApplication}
              />
            </div>
          </div>
        </Container>
      </div>
      {openModal && (
        <RestrictedApplicationAdmin
          restricted_data={record}
          open={openModal}
          onClose={closeModal}
          data={modalData}
          user={modalUser}
        />
      )}
    </div>
  )
}

export default RestrictedDataShow