import React, { Component } from 'react'
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Header,
  Icon,
  Loader,
  Segment,
} from 'semantic-ui-react'

import { fileTypes } from '../../constants'
import { randomKeyString } from '../../utils'

class SurveyForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: null,
      name: '',
      description: '',
      availableTopics: [],
      availableKeywords: [],
      files: [],
      topics: [],
      keywords: [],
      inputFileType: '',
      inputFileError: {},
      active: true,
    }

    this.addFile = this.addFile.bind(this)
    this.removeFile = this.removeFile.bind(this)
    this.handleFileTypeChange = this.handleFileTypeChange.bind(this)
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.handleTopicAddition = this.handleTopicAddition.bind(this)
    this.handleKeywordAddition = this.handleKeywordAddition.bind(this)

    this.handleTopicChange = this.handleTopicChange.bind(this)
    this.handleKeywordChange = this.handleKeywordChange.bind(this)

    this.toggleActive = this.toggleActive.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    if (this.props.data && typeof this.props.data === 'object') {
      const { name,
        description,
        topics,
        keywords,
        active
      } = this.props.data

      const keywordObjects = keywords.map((keyword, idx) => {
        return {
          text: keyword,
          value: keyword,
        }
      })

      const topicObjects = topics.map((topic, idx) => {
        return {
          text: topic,
          value: topic,
        }
      })

      const { survey_files, restricted_data_files } = this.props.data
      let files = survey_files ? survey_files : restricted_data_files

      if (!files) {
        files = []
      }

      this.setState({
        name,
        description,
        topics,
        keywords,
        availableTopics: topicObjects,
        availableKeywords: keywordObjects,
        files,
        active
      })
    }
  }

  addFile() {
    let { files } = this.state
    const { inputFileType } = this.state

    if (files === undefined || files === null) {
        files = []
    }

    if (inputFileType === '') {
      this.setState({
        inputFileError: { 'fileType': 'Please select a file type' }
      })
      return
    }

    const newFileObj = {
      type: inputFileType,
      key: randomKeyString(7)
    }

    files.push(newFileObj)
    this.setState({
      files: files,
      inputFileType: '',
      inputFileError: {}
    })
  }

  handleFieldChange(e, { value }) {
    let changes = {}
    changes[e.target.name] = value
    this.setState(changes)
  }

  handleTopicChange(e, { value }) {
    this.setState({
      topics: value
    })
  }

  handleKeywordChange(e, { value }) {
    this.setState({
      keywords: value
    })
  }

  handleTopicAddition(e, { value }) {
    const inputValue = value
    this.setState((prevState) => ({
      availableTopics: [{ text: inputValue, value: inputValue }, ...prevState.availableTopics],
    }))
  }

  handleKeywordAddition(e, { value }) {
    const inputValue = value

    this.setState((prevState) => ({
      availableKeywords: [{ text: inputValue, value: inputValue }, ...prevState.availableKeywords],
    }))
  }

  toggleActive() {
    this.setState({
      active: !this.state.active
    })
  }

  removeFile(fileKey) {
    const { files } = this.state

    const updatedFiles = files.filter((file) => {
      return file.key !== fileKey
    })

    this.setState({
      files: updatedFiles
    })
  }

  handleFileTypeChange(e, { value }) {
    this.setState({
      inputFileType: value
    })
  }

  renderFileInputs() {
    const { files } = this.state

    if (files === undefined || !files) {
      return
    }

    return files.map((file, idx) => {
      const typeFilter = fileTypes.filter((type) => {
        return type.value === file.type
      })

      const selectedType = typeFilter.length == 1 ? typeFilter[0] : 'File'

      // Basically having an _id
      if (file._id) {
        file.key = file._id.$oid

        return (
          <Segment id={`file-${idx}`} key={`file-${file.key}`}>
            <Icon link style={{ float: 'right' }} corner='top right' name='times' onClick={() => this.removeFile(file.key)}/>

            <Form.Field>
              <label>Name</label>
              <input type="text" name={`${file.key}-name`} id={`file-${file.key}-name`} defaultValue={file.name}/>
              <input type="hidden" name={`${file.key}-_id`} id={`file-${file.key}-_id`} defaultValue={file._id.$oid}/>
              <input type="hidden" name={`${file.key}-filename`} id={`file-${file.key}-filename`} defaultValue={file.filename}/>
            </Form.Field>

            <Form.Field>
              <label>{selectedType.text}</label>
              <label>{file.filename}</label>
            </Form.Field>
          </Segment>
        )
      }

      return (
        <Segment id={`file-${idx}`} key={`file-${file.key}`}>
          <Icon link style={{ float: 'right' }} corner='top right' name='times' onClick={() => this.removeFile(file.key)}/>

          <Form.Field>
            <label>Name</label>
            <input type="text" name={`${file.key}-name`} id={`file-${file.key}-name`} />
            <span className="help-block">
              <small>
                Leave this field blank if you want to use the uploaded file's name
              </small>
            </span>
          </Form.Field>

          <Form.Field>
            <label>{selectedType.text}</label>
            { file._id ? <label>{file.name}</label> : ''}
            <input type="hidden" name={`${file.key}-type`} id={`file-${file.key}-type`} defaultValue={selectedType.value}/>
            <input type="file" name={file.key} id={`file-${file.key}`} />
          </Form.Field>
        </Segment>
      )
    })
  }

  handleSubmit() {
    const { id, name, description, topics, keywords, active, files } = this.state
    const surveyObj = {
      survey: {
        name,
        description,
        topics,
        keywords
      }
    }

    const fileData = []

    const bodyFormData = new FormData()

    bodyFormData.set('survey[name]', name)
    bodyFormData.set('survey[description]', description)
    bodyFormData.set('survey[active]', active ? 1 : 0)


    topics.forEach((topic) => {
      bodyFormData.append('survey[topics][]', topic)
    })

    keywords.forEach((keyword) => {
      bodyFormData.append('survey[keywords][]', keyword)
    })

    files.forEach((file) => {
      bodyFormData.append(`files[${file.key}][name]`, document.getElementById(`file-${file.key}-name`).value)

      if (file._id) {
        bodyFormData.append(`files[${file.key}][_id]`, document.getElementById(`file-${file.key}-_id`).value)
        bodyFormData.append(`files[${file.key}][filename]`, document.getElementById(`file-${file.key}-filename`).value)
      } else {
        bodyFormData.append(`files[${file.key}][file]`, document.getElementById(`file-${file.key}`).files[0])
        bodyFormData.append(`files[${file.key}][type]`, document.getElementById(`file-${file.key}-type`).value)
      }
    })

    if (this.props.data && typeof this.props.data === 'object') {
      this.props.onSubmit(bodyFormData, this.props.data._id.$oid)
    } else {
      this.props.onSubmit(bodyFormData)
    }
  }

  render() {
    const { name, description, availableTopics, availableKeywords, topics, keywords } = this.state

    return (
      <Form>
        <Form.Field required>
          <label>Name</label>
          <Form.Input name='name' value={name} onChange={this.handleFieldChange} />
        </Form.Field>

        <Form.Field required>
          <label>Description</label>
          <Form.TextArea name='description' value={description} onChange={this.handleFieldChange} />
        </Form.Field>

        <Form.Field>
          <label>Topics</label>
          <Dropdown
            id='topics'
            search
            selection
            multiple
            allowAdditions
            fluid
            options={availableTopics}
            onAddItem={this.handleTopicAddition}
            noResultsMessage={null}
            onChange={this.handleTopicChange}
            value={topics} />
        </Form.Field>

        <Form.Field>
          <label>Keywords</label>
          <Dropdown
            id='keywords'
            search
            selection
            multiple
            allowAdditions
            fluid
            options={availableKeywords}
            onAddItem={this.handleKeywordAddition}
            noResultsMessage={null}
            onChange={this.handleKeywordChange}
            value={keywords} />
        </Form.Field>

        <Form.Field>
          <Checkbox label="Active" checked={this.state.active} onChange={this.toggleActive} /><br />
          <span>
            <small>Marking a survey <strong>Active</strong> will make it available for users to view.</small>
          </span>
        </Form.Field>

        <Divider />

        <Header as='h4'>Files</Header>

        <Form.Group>
          <Form.Field width={12}>
            <Dropdown
              placeholder='Select file type'
              fluid
              selection
              options={fileTypes}
              onChange={this.handleFileTypeChange}
              value={this.state.inputFileType}
              error={'fileType' in this.state.inputFileError}
            />
          </Form.Field>
          <Form.Field width={4}>
            <Button
              positive
              icon
              labelPosition='left'
              onClick={this.addFile}
              disabled={this.state.inputFileType == ''}
            >
              <Icon name='file alternate outline' />
              Add File
            </Button>
          </Form.Field>
        </Form.Group>

        {this.renderFileInputs()}

        <Divider />

        <Button
          positive
          disabled={name.trim() == '' || description.trim() == '' || this.props.isLoading}
          onClick={this.handleSubmit}
        >
          {this.props.isLoading ? <Loader active inline/> : 'Save' }
        </Button>
      </Form>
    )
  }

}

export default SurveyForm
