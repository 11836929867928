import React, { Component } from 'react';
import { Card, Segment } from 'semantic-ui-react'

import SurveyGridItem from './survey_grid_item'

class SurveyGrid extends Component {

  render() {
    return (
      <Card.Group itemsPerRow={3} stackable>
        {this.props.surveys.map((survey, idx) => {
          return (
            <SurveyGridItem
              key={`survey-grid-item-${survey._id.$oid}`}
              showSurveyModal={this.props.showSurveyModal}
              survey={survey}
            />
          )
        })}
      </Card.Group>
    );
  }

}

export default SurveyGrid;
