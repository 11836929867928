import React, { Component } from 'react'
import { Table, Label } from 'semantic-ui-react'
import moment from 'moment'

const userStatus = (user) => {
  if (!user.access_granted && !user.access_denied) {
    return (
      <Label color='grey'>Registered</Label>
    )
  } else if (user.access_granted) {
    return (
      <Label color='green'>Granted</Label>
    )
  } else if (user.access_denied) {
    return (
      <Label color='red'>Denied</Label>
    )
  } else {
    return 'Invalid'
  }
}

const UserRow = (props) => (
  <Table.Row
    onClick={() => props.showUserModal(props.user._id.$oid)}
  >
    <Table.Cell>
      {`${props.user.first_name} ${props.user.last_name}`}
    </Table.Cell>
    <Table.Cell>{props.user.email}</Table.Cell>
    <Table.Cell>{`${moment(props.user.created_at).format('MMM D, YYYY')}`}</Table.Cell>
    <Table.Cell>{userStatus(props.user)}</Table.Cell>
  </Table.Row>
)

export default UserRow
