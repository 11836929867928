import React from 'react'
import moment from 'moment'
import {
  Dropdown,
  Label,
  Table
} from 'semantic-ui-react'

const RestrictedDataRow = ({ record, handleEdit, handleDelete }) => {
  return (
    <Table.Row>
      <Table.Cell width={7}>{record.name}</Table.Cell>
      <Table.Cell width={2}>
        {`${moment(record.updated_at).format('MMM D, YYYY')}`}
      </Table.Cell>
      <Table.Cell width={1}>
        {record.open_count}
      </Table.Cell>
      <Table.Cell width={1}>
        {record.total_count}
      </Table.Cell>
      <Table.Cell width={2}>
        <Label color={record.active ? 'green' : 'grey'}>
          {record.active ? 'Active' : 'Inactive'}
        </Label>
      </Table.Cell>
      <Table.Cell width={2}>
        <Dropdown
          inline
          icon='ellipsis horizontal'
          className='link item'
        >
          <Dropdown.Menu>
            <Dropdown.Item
              as="a"
              icon='info circle'
              text='Details'
              href={`/restricted_data/${record._id.$oid}`}
            />
            <Dropdown.Item
              icon='edit'
              text='Edit'
              onClick={() => handleEdit(record._id.$oid)}
            />
            <Dropdown.Divider />
            <Dropdown.Item
              icon='cancel'
              text='Delete'
              onClick={() => handleDelete(record._id.$oid)}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Table.Cell>
    </Table.Row>
  )
}

export default RestrictedDataRow