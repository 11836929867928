import React, { useEffect, useCallback, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import get from 'lodash/get'
import truncate from 'lodash/truncate'
import orderBy from 'lodash/orderBy'
import {
    Button,
    Container,
    Confirm,
    Dimmer,
    Dropdown,
    Form,
    Header,
    Icon,
    Input,
    Loader,
    Pagination,
    Popup,
    Segment,
    Table
} from 'semantic-ui-react'

import FAQModal from './faq-modal'
const csrfToken = document.getElementsByName('csrf-token')[0].content

function FAQs() {
    const [faqs, setFaqs] = useState([])
    const [loading, toggleLoading] = useState(true)
    const [restricted, toggleRestricted] = useState(true)
    const [showModal, toggleModal] = useState(false)
    const [selectedData, setSelectedData] = useState(null)
    const [inFlight, toggleInFlight] = useState(false)
    const [deleteData, setDeleteData] = useState(null)

    const loadFaqs = useCallback(async () => {
        try {
            const params = {}
            if (restricted) {
                params['restricted'] = true
            }
            const response = await axios.get(`/api/v1/faqs`, { params })
            setFaqs(response.data)
            toggleLoading(false)
        } catch (err) {
            console.error('err', err)
            console.error('err.stack', err.stack)
            toggleLoading(false)
        }
    }, [restricted])

    const createFaq = async (data) => {
        try {
            toggleInFlight(true)
            const response = await axios.post(`/api/v1/faqs`, { faq: data }, {
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                }
            })
            // setFaqs((prev) => {
            //     const updatedFaqs = prev.slice()
            //     updatedFaqs.push(response.data)
            //     return orderBy(updatedFaqs, ['order', 'asc'])
            // })
            loadFaqs()
            handleClose()
        } catch (err) {
            console.error('err', err)
            console.error('err.stack', err.stack)
            toggleInFlight(false)
        }
    }

    const updateFaq = async (id, data) => {
        try {
            toggleInFlight(true)
            const response = await axios.put(`/api/v1/faqs/${id}`, { faq: data }, {
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                }
            })
            // setFaqs((prev) => {
            //     const updatedFaqs = prev.slice()
            //     const faqIndex = updatedFaqs.findIndex((faq) => {
            //         return get(faq, '_id.$oid') === get(response, 'data._id.$oid')
            //     })
            //     updatedFaqs.splice(faqIndex, 1, response.data)
            //     return orderBy(updatedFaqs, ['order', 'asc'])
            // })
            loadFaqs()
            handleClose()
        } catch (err) {
            console.error('err', err)
            console.error('err.stack', err.stack)
            toggleInFlight(false)
        }
    }

    const deleteFaq = async () => {
        try {
            const id = get(deleteData, '_id.$oid')
            const response = await axios.delete(`/api/v1/faqs/${id}`, {
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                }
            })
            loadFaqs()
            // setFaqs((prev) => {
            //     const updatedFaqs = prev.slice()
            //     const faqIndex = updatedFaqs.findIndex((faq) => {
            //         return get(faq, '_id.$oid') === get(response, 'id')
            //     })
            //     updatedFaqs.splice(faqIndex, 1)
            //     return orderBy(updatedFaqs, ['order', 'asc'])
            // })
            setDeleteData(null)
        } catch (err) {
            console.error('err', err)
            console.error('err.stack', err.stack)
        }
    }

    const toggleEdit = (id) => {
        setSelectedData(faqs.find((faq) => get(faq, '_id.$oid') === id))
        toggleModal(true)
    }

    const handleClose = () => {
        setSelectedData(null)
        toggleModal(false)
        toggleInFlight(false)
    }

    useEffect(() => {
        loadFaqs()
    }, [loadFaqs])

    return (
        <div id="faqs" className='aep-admin__container'>
            <Container>
                <div className='aep-admin__header'>
                    <Segment basic className="aep-admin__buttons" floated="right">
                        <Button
                            icon
                            labelPosition='left'
                            style={{ 'marginBottom': '1em' }}
                            onClick={() => toggleModal(true)}
                        >
                            <Icon name='plus' />
                            Add FAQ
                        </Button>
                        <Button.Group>
                            <Button icon basic disabled>
                                <Icon name="eye" />
                            </Button>
                            <Button icon basic active>
                                <Icon name="ban" />
                            </Button>
                        </Button.Group>
                    </Segment>
                    <Header as="h1">
                        FAQs
                        {restricted && (
                            <Header.Subheader>Restricted</Header.Subheader>
                        )}
                    </Header>
                </div>
                <Segment>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>Title</Table.HeaderCell>
                                <Table.HeaderCell>Active</Table.HeaderCell>
                                <Table.HeaderCell>Last Updated</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Dimmer.Dimmable as={Table.Body} active={loading.toString()}>
                            <Table.Row>
                                <Dimmer as={Table.Cell} active={loading} inverted>
                                    <Loader active inline />
                                </Dimmer>
                            </Table.Row>
                            {!loading && faqs.length === 0 && (
                                <Table.Row>
                                    <Table.Cell colSpan="3" textAlign="center">
                                        No FAQs.
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            {!loading && faqs.length > 0 && faqs.map((faq) => (
                              <Table.Row key={get(faq, '_id.$oid')}>
                                  <Table.Cell>{get(faq, 'order')}</Table.Cell>
                                  <Table.Cell>
                                      <Popup
                                        content={get(faq, 'title')}
                                        wide="very"
                                        trigger={(
                                            <span className="faq-title-text" style={{borderBottom: '1px dotted #000', textDecoration: 'none'}}>
                                                {truncate(get(faq, 'title'), {length: '96', omission: '...'})}
                                            </span>
                                        )}
                                      />
                                  </Table.Cell>
                                  <Table.Cell>
                                      {get(faq, 'active') ? <Icon name="check" color="green"/> : <Icon name="cancel" color="red" />}
                                  </Table.Cell>
                                  <Table.Cell>
                                      {moment(get(faq, 'updated_at')).format('MM/DD/YYYY')}
                                  </Table.Cell>
                                  <Table.Cell>
                                      <Dropdown
                                          inline
                                          icon='ellipsis horizontal'
                                          className='link item'
                                      >
                                          <Dropdown.Menu>
                                              <Dropdown.Item
                                                  icon='edit'
                                                  text='Edit'
                                                  onClick={() => toggleEdit(get(faq, '_id.$oid'))}
                                              />
                                              <Dropdown.Divider />
                                              <Dropdown.Item
                                                  icon='cancel'
                                                  text='Delete'
                                                  onClick={() => setDeleteData(faq)}
                                              />
                                          </Dropdown.Menu>
                                      </Dropdown>
                                  </Table.Cell>
                              </Table.Row>
                            ))}
                        </Dimmer.Dimmable>
                    </Table>
                </Segment>
            </Container>
            {showModal && (
                <FAQModal
                    loading={inFlight}
                    data={selectedData}
                    inputOrder={selectedData ? get(selectedData, 'order', 1) : faqs.length + 1}
                    maxOrder={faqs.length+1}
                    onSave={selectedData ? updateFaq : createFaq}
                    onClose={handleClose}
                />
            )}
            {deleteData && (
                <Confirm
                    open={true}
                    size="mini"
                    content="Are you sure you want to remove this FAQ?"
                    onCancel={() => setDeleteData(null)}
                    onConfirm={() => deleteFaq()}
                />
            )}
        </div>
    )
}

export default FAQs