import React, { Component } from 'react';
import {
  Button,
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Input,
  Menu,
  Pagination,
  Segment } from 'semantic-ui-react'
import axios from 'axios'

import SurveyGrid from './survey_grid'
import SurveyList from './survey_list'

import ModalContainer from '../modals/modal_container'
import { initGA, Event } from "../Tracking";

const SURVEYS_PER_PAGE = 12

const sortByOptions = [
  { text: 'Name', value: 'name'},
  { text: 'Date Created', value: 'created_at'},
  { text: 'Date Updated', value: 'updated_at'},
]

class SurveyView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activePage: 1,
      activeSurvey: null,
      displayMode: 'list',
      isLoading: true,
      query: '',
      sortBy: 'name',
      surveys: [],
      surveyCount: null,
    }
  }

  componentDidMount() {
    const { trackingId } = this.props
    this.getSurveys()
    if (trackingId) {
      initGA(trackingId)
    }
  }

  getSurveys = (resetPage) => {
    const { activePage, query, sortBy } = this.state

    const reqPage = resetPage ? 1 : activePage

    const getParams = {
      page: reqPage,
      sort_by: sortBy
    }

    if (query.trim() !== '') {
      getParams['query'] = query
    }

    axios.get('/api/v1/surveys', {
      params: getParams
    })
      .then((response) => {
        this.setState({
          surveys: response.data.surveys,
          surveyCount: response.data.survey_count
        })
      })
  }

  handlePaginationChange = (e, { activePage }) => {
    if (activePage !== this.state.activePage ) {
      this.setState({
        activePage: activePage
      }, () => {
        this.getSurveys()
      })
    }
  }

  changeSortBy = (e, { value }) => {
    const { sortBy } = this.state
    if (value !== sortBy) {
        this.setState({
          sortBy: value
        }, () => {
          this.getSurveys(true)
        })
    }
  }

  toggleDisplayMode = (mode) => {
    const { displayMode } = this.state
    if (mode !== displayMode) {
      this.setState({
        displayMode: mode
      })
    }
  }

  showSurveyModal = (surveyId) => {
    axios.get(`/api/v1/surveys/${surveyId}`)
      .then((response) => {
        const { data } = response
        const { survey } = data

        if (this.props.trackingId) {
          Event('deidentified', 'view', survey.name)
        }

        this.setState({
          activeSurvey: survey
        })
      })
  }

  onCloseSurveyModal = () => {
    this.setState({
      activeSurvey: null
    })
  }

  pageShowing = () => {
    const { activePage, surveyCount } = this.state
    let firstOnPage, lastOnPage

    firstOnPage = ((activePage - 1) * SURVEYS_PER_PAGE) + 1

    if (activePage * SURVEYS_PER_PAGE > surveyCount) {
      lastOnPage = surveyCount
    } else {
      lastOnPage = activePage * SURVEYS_PER_PAGE
    }

    console.log('firstOnPage', firstOnPage)
    console.log('lastOnPage', lastOnPage)


    return (
      <p>{`${firstOnPage}-${lastOnPage} of ${surveyCount}`}</p>
    )
  }

  handleChange = (e, { value }) => {
    this.setState({
      query: value
    })
  }

  onDownload = (filename) => {
    if (this.props.trackingId) {
      Event('deidentified', 'download', filename)
    }
  }

  submitQuery = () => {
    this.getSurveys(true)
  }

  render() {
    const {
      activePage,
      activeSurvey,
      displayMode,
      isLoading,
      query,
      sortBy,
      surveys,
      surveyCount } = this.state

    console.log('this.state', this.state)


    return (
      <div id="survey-view" style={{ paddingTop: '3rem'}}>
        <div
          id="survey-view__search"
          basic
        >
          <Input
              action={{ content: 'Search', onClick: this.submitQuery }}
              fluid
              value={query}
              onChange={this.handleChange}
            />
          <div
            id="survey-view__meta"
            verticalAlign='bottom'
          >
            <Menu secondary pointing borderless>
              <Menu.Item>
                All results ({surveyCount})
              </Menu.Item>
            </Menu>
          </div>
        </div>

        <Container>
          <Segment>
            <Grid columns={1}>
              <Grid.Column>
                <Grid.Row
                  className='survey-view__display'
                >
                  <Container>
                    <div className='survey-view__sort'>
                      <span>Sort by:</span>
                      <Dropdown
                        selection
                        onChange={this.changeSortBy}
                        value={sortBy}
                        options={sortByOptions}
                      />
                    </div>

                    <Button.Group>
                      <Button
                        active={displayMode == 'grid' ? true : undefined}
                        icon='grid layout'
                        onClick={this.toggleDisplayMode.bind(null, 'grid')}
                      />
                      <Button
                        active={displayMode == 'list' ? true : undefined}
                        icon='list layout'
                        onClick={this.toggleDisplayMode.bind(null, 'list')}
                      />
                    </Button.Group>
                  </Container>
                </Grid.Row>
                <Grid.Row>
                  <Container>
                    { displayMode == 'grid' ?
                      <SurveyGrid
                        surveys={surveys}
                        showSurveyModal={this.showSurveyModal}
                      /> :
                      <SurveyList
                        surveys={surveys}
                        showSurveyModal={this.showSurveyModal}
                      />
                    }
                  </Container>
                </Grid.Row>
              </Grid.Column>
            </Grid>

            { activeSurvey ?
              <ModalContainer
                type="SurveyModal"
                data={activeSurvey}
                handleEvent={this.onDownload}
                onClose={this.onCloseSurveyModal}
              /> :
              ''
            }


            <Divider />
            <Grid>
              <Grid columns={3}>
                <Grid.Column textAlign='left' verticalAlign='middle'>
                  {this.pageShowing()}
                </Grid.Column>

                <Grid.Column
                  className="survey-view__pagination"
                  textAlign='center'
                  verticalAlign='middle'
                >
                  <Pagination
                    activePage={activePage}
                    boundaryRange={1}
                    firstItem={null}
                    lastItem={null}
                    onPageChange={this.handlePaginationChange}
                    secondary
                    siblingRange={1}
                    size='tiny'
                    totalPages={Math.ceil(surveyCount / SURVEYS_PER_PAGE)}
                  />
                </Grid.Column>
              </Grid>
            </Grid>
          </Segment>
        </Container>
      </div>
    )
  }

}

export default SurveyView;
