import _ from 'lodash'
import React, { Component } from 'react'
import AdminRow from './admin_row'

import { Header, Icon, Table } from 'semantic-ui-react'

class AdminsTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      column: null,
      data: props.admins,
      direction: null
    }

    this.handleSort = this.handleSort.bind(this)
  }

  handleSort(clickedColumn) {
    const { column, data, direction } = this.state

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: 'ascending'
      })
      return
    }

    this.setState( {
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending'
    })
  }

  renderAdminRows() {
    const { data } = this.state

    if (data.length > 0) {
      return data.map((admin, idx) => {
        return (
          <AdminRow key={`admin-${idx}`} admin={admin} />
        )
      })
    } else {
      return(
        <Table.Row>
          <Table.Cell colSpan="3" textAlign="center" verticalAlign="middle">
            <Icon name="user outline" size="big" /><br />
            No Admins
          </Table.Cell>
        </Table.Row>
      )
    }
  }

  render() {
    const { column, direction } = this.state

    return (
      <div>
        <Table celled sortable fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'first_name' ? direction : null}
                onClick={() => this.handleSort('first_name')}
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'email' ? direction : null}
                onClick={() => this.handleSort('email')}
              >
                Email
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'created_at' ? direction : null}
                onClick={() => this.handleSort('created_at')}
              >
                Date Created
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.renderAdminRows()}
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default AdminsTable
