import React, { Component } from 'react';
import { Table } from 'semantic-ui-react'

import SurveyRow from './survey_row'

class SurveyTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data
    }
  }

  renderSurveyRows() {
    const { data } = this.state
    if (data.length > 0) {
      return data.map((survey, idx) => {
        return (
          <SurveyRow
            survey={survey}
            key={`survey-row-${idx}`}
            showSurveyModal={this.props.showSurveyModal}
            showDeleteSurveyModal={this.props.showDeleteSurveyModal}
            showEditSurveyModal={this.props.showEditSurveyModal}
            showMetricsModal={this.props.showMetricsModal}
          />
        )
      })
    } else {
      return (
        <Table.Row>
          <Table.Cell colSpan={5} textAlign='center'>No Surveys</Table.Cell>
        </Table.Row>
      )
    }
  }

  render() {
    return (
      <Table id="survey-table" celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Date Updated</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.renderSurveyRows()}
        </Table.Body>
      </Table>
    );
  }

}

export default SurveyTable;
