import React from 'react'
import { Form, TextArea } from 'semantic-ui-react'

function Addon({
  data,
  handleChange,
  wordLimits,
  wordCounts,
}) {
  return (
    <div className="restricted-add-on">
      <Form>
        <Form.Field
          required
          error={wordCounts.justification > wordLimits.justification}
        >
          <label>Why is restricted data necessary for your proposed research? Why is deidentified data insufficient?</label>
          <TextArea
            onChange={(e, { value}) => handleChange('justification', value)}
            value={data.justification}
          />
          <div className="word-limit">
            {wordCounts.justification} / {wordLimits.justification} words
          </div>
        </Form.Field>

        <Form.Field
          required
          error={wordCounts.security_statement > wordLimits.security_statement}
        >
          <label>How will you securely maintain the data and prevent unauthorized access?:</label>
          <TextArea
            onChange={(e, { value}) => handleChange('security_statement', value)}
            value={data.security_statement}
          />
          <div className="word-limit">
            {wordCounts.security_statement} / {wordLimits.security_statement} words
          </div>
        </Form.Field>
      </Form>
    </div>
  )
}

export default Addon