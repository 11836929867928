import React, { useState, useEffect } from 'react'
import { Form } from 'semantic-ui-react'


const positionOptions = [
  {
    key: 'faculty',
    text: 'Faculty',
    value: 'Faculty',
  },
  {
    key: 'postdoc',
    text: 'Post-doc/Fellow',
    value: 'Post-doc/Fellow',
  },
  {
    key: 'principal',
    text: 'Principal/Teacher',
    value: 'Principal/Teacher',
  },
  {
    key: 'staff',
    text: 'Staff',
    value: 'Staff',
  },
  {
    key: 'student',
    text: 'Student',
    value: 'Student',
  },
  {
    key: 'other',
    text: 'Other',
    value: 'Other',
  },
]

const ProfilePosition = (props) => {
  const [position, setPosition] = useState('')
  const [otherPosition, setOtherPosition] = useState('')

  useEffect(() => {
    setPosition(props.position)
    if (props.otherPosition) {
      setOtherPosition(props.otherPosition)
    }
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'user[position]') {
      setPosition(value)
    } else {
      setOtherPosition(value)
    }
  }

  return (
    <div id="profile-position">
       <Form.Field>
          <label>Position</label>
          <select
            className="ui fluid dropdown"
            id="position"
            name="user[position]"
            value={position}
            onChange={handleChange}
          >
            <option value="">
              Please select position
            </option>
            {positionOptions.map((position) =>
              <option key={position.key} value={position.value}>
                {position.text}
              </option>
            )}
          </select>
        </Form.Field>
        { position === 'Other'
            ?
            (
              <Form.Field>
                <Form.Input
                  id="other_position"
                  name="user[other_position]"
                  value={otherPosition}
                  placeholder="Please specify"
                  onChange={handleChange}
                />
              </Form.Field>
            )
            : <input type="hidden" name="user[other_position]" value="" />
            }
    </div>
  )
}

export default ProfilePosition