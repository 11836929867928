import React from 'react'
import { Divider, List } from 'semantic-ui-react'

function Review({ data, restrictedData, }) {
  return (
    <div className="scholarship-application--review">
      <p>Please review your application before submitting:</p>
      <Divider />
      <List>
        <List.Item>
          <List.Header>Ethnicity</List.Header>
          <List.Description>
            {data.ethnicity.join(', ')}
            {data.ethnicity.indexOf('Other') !== -1
              && data.ethnicity_other.trim() !== '' && (
              <p>
                If 'Other', specify: <strong>{data.ethnicity_other}</strong>
              </p>
            )}
          </List.Description>
        </List.Item>
        <List.Item>
          <List.Header>
            How did you find out about this scholarship? Include the name of the organization or listserv if applicable.
          </List.Header>
          <List.Description>{data.find_out}</List.Description>
        </List.Item>
        <List.Item>
          <List.Header>
            What makes you a good fit for this scholarship? Please keep in mind the eligibility criteria.
          </List.Header>
          <List.Description>{data.fit}</List.Description>
        </List.Item>
        <List.Item>
          <List.Header>
            Have you previously analyzed micro-level data files?
          </List.Header>
          <List.Description>
            {data.analyzed_micro_level ? 'Yes' : 'No'}
          </List.Description>
        </List.Item>
        <List.Item>
          <List.Header>Preferred statistical package platform:</List.Header>
          <List.Description>
            {data.preferred_stat_platform}
          </List.Description>
        </List.Item>
        <List.Item>
          <List.Header>Summary of research</List.Header>
          <List.Description>{data.summary}</List.Description>
        </List.Item>
        <List.Item>
          <List.Header>Data file/s of interest</List.Header>
          <List.Description>{data.data_interest}</List.Description>
        </List.Item>
        <List.Item>
          <List.Header>
            Do you anticipate needing access to restricted data?
          </List.Header>
          <List.Description>
            {data.access_to_restricted_data ? 'Yes' : 'No'}
          </List.Description>
        </List.Item>
        {data.access_to_restricted_data && (
          <React.Fragment>
            <List.Item>
              <List.Header>Justification for restricted data </List.Header>
              <List.Description>
                {restrictedData.justification}
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>How will you securely maintain the data and prevent unauthorized access?</List.Header>
              <List.Description>
                {restrictedData.security_statement}
              </List.Description>
            </List.Item>
          </React.Fragment>
        )}
      </List>
      <Divider />
      <p><strong>Please note, if your application is accepted you will need to provide evidence of IRB approval or exemption.</strong></p>
      <p>
        Thank you for your interest in using AEP data. Click the “Submit” button below to complete your application.
      </p>
    </div>
  )
}

export default Review