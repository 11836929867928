import ReactGA from 'react-ga';

export const initGA = (trackingId) => {
    ReactGA.initialize(trackingId);
}

export const Event = (category, action, label) => {
    ReactGA.event({
        category,
        action,
        label,
    })
}