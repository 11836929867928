function dec2hex (dec) {
  return ('0' + dec.toString(16)).substr(-2)
}

export const randomKeyString = (len) => {
  var arr = new Uint8Array((len || 40) / 2)
  var crypto = window.crypto || window.msCrypto
  crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}
