import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
  Button,
  Container,
  Icon,
  Header,
} from 'semantic-ui-react'
import DataTable from 'components/data_table'
import RestrictedModal from './restricted_modal'
import RestrictedDataContext from './context.js'
import RestrictedDataRow from './restricted_data_row'
import PageEditor from 'components/page_editor'

const csrfToken = document.getElementsByName('csrf-token')[0].content

const RestrictedData = () => {
  const [isLoading, toggleLoading] = useState(false)
  const [data, setData] = useState(null)
  const [openModal, toggleModal] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [mode, setMode] = useState(null)
  const [pageModal, togglePageModal] = useState(false)

  const onClosePageModal = () => {
    togglePageModal(false)
  }

  const onToggleNew = () => {
    toggleModal(true)
    setMode('new')
  }

  const onToggleEdit = async (id) => {
    try {
      const response = await axios.get(`/api/v1/restricted_data/${id}`)
      setModalData(response.data.survey)
      toggleModal(true)
      setMode('edit')
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    }
  }

  const onModalClose = () => {
    toggleModal(false)
    setMode(null)
    setModalData(null)
  }

  const fetchRestrictedData = async () => {
    try {
      const response = await axios.get('/api/v1/admin/restricted_data')
      setData(response.data)
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    }
  }

  useEffect(() => {
    fetchRestrictedData()
  }, [])

  const createRestrictedData = async (formData) => {
    try {
      const response = await axios.post('/api/v1/restricted_data', formData, {
        headers: {
          'X-CSRF-TOKEN': csrfToken,
          'Content-Type': 'multipart/form-data'
        }
      })
      console.log('response', response)
      onModalClose()
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    } finally {
      fetchRestrictedData()
    }
  }

  const updateRestrictedData = async (formData, id) => {
    try {
      const response = await axios.put(`/api/v1/restricted_data/${id}`, formData, {
        headers: {
          'X-CSRF-TOKEN': csrfToken,
          'Content-Type': 'multipart/form-data'
        }
      })
      console.log('response', response)
      onModalClose()
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    } finally {
      fetchRestrictedData()
    }
  }

  const removeRestrictedData = async (id) => {
    try {
      try {
        const response = await axios.delete(`/api/v1/restricted_data/${id}`,
          {headers: {
            'X-CSRF-TOKEN': csrfToken,
            'Content-Type': 'multipart/form-data'
          }}, {}
        )
        console.log('response', response)
        onModalClose()
      } catch (err) {
        console.error('err', err)
        console.error('err.stack', err.stack)
      }
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    } finally {
      fetchRestrictedData()
    }
  }

  const contextValue = {
    open: openModal,
    handleClose: onModalClose,
    mode,
    handleSubmit: mode === 'new' ? createRestrictedData : updateRestrictedData,
    data: modalData,
  }

  return (
    <RestrictedDataContext.Provider value={contextValue}>
      <div id="restricted-data" className="aep-admin__container">
        <Container >
          <div className="aep-admin__header">
            <Button
              floated="right"
              icon
              labelPosition='left'
              style={{ 'marginBottom': '1em' }}
              onClick={() => togglePageModal(true)}
            >
              <Icon name='edit' />
              Edit Page
            </Button>
            <Button
              floated="right"
              icon
              labelPosition='left'
              style={{ 'marginBottom': '1em' }}
              onClick={onToggleNew}
            >
              <Icon name='book' />
              Add Restricted Data
            </Button>
            <Header as='h1'>Restricted Data</Header>
          </div>
          <div className="aep-admin--restricted-data">
            <DataTable>
              {data && data.surveys.map((record) => {
                return (
                  <RestrictedDataRow
                    key={`restricted-data-${record._id.$oid}`}
                    record={record}
                    handleEdit={onToggleEdit}
                    handleDelete={removeRestrictedData}
                  />
                )
              })}
            </DataTable>
          </div>
        </Container>
        <RestrictedModal />
        {pageModal && (
          <PageEditor
            open={pageModal}
            onClose={onClosePageModal}
            slug="restricted-data"
          />
        )}
      </div>
    </RestrictedDataContext.Provider>
  )
}

export default RestrictedData