import React from 'react'
import { Breadcrumb } from 'semantic-ui-react'

function ScholarshipBreadcrumb({record}) {
  return (
    <Breadcrumb>
      <Breadcrumb.Section as="a" href="/">Home</Breadcrumb.Section>
      <Breadcrumb.Divider />
      <Breadcrumb.Section as="a" href="/scholarships">
        Scholarships
      </Breadcrumb.Section>
      <Breadcrumb.Divider />
      <Breadcrumb.Section active>{record.name}</Breadcrumb.Section>
    </Breadcrumb>
  )
}

export default ScholarshipBreadcrumb
