import React from 'react'
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Icon,
  List,
} from 'semantic-ui-react'

// # Pending - 0
// # Denied - 1
// # Granted - 2
// # Awaiting Payment - 3
// # Fulfilled - 4

const statusOptions = [
  {key: 'pending', value: 0, text: 'Pending'},
  {key: 'denied', value: 1, text: 'Denied'},
  {key: 'granted', value: 2, text: 'Granted'},
  {key: 'awaiting_payment', value: 3, text: 'Awaiting Payment'},
  {key: 'fulfilled', value: 4, text: 'Fulfilled'},
]

function AdminReview({
  applicationStatus,
  data,
  onChange,
  onSubmit,
  user,
}) {
  return (
    <div className="restricted-application-review">
      <div className="applicant">
        <List>
          <List.Item>
            <List.Header>Applicant</List.Header>
            <List.Description>
              <p>
                {`${user.first_name} ${user.last_name}`}
                &nbsp;
                ({user.email})
                &nbsp;
                <a
                  href={`/api/v1/restricted_applications/${user._id.$oid}/cv`}
                  target="_blank"
                >
                  <Icon name="file" />
                  CV
                </a>
                <br />
                <span>
                  <b>Organization:</b> {user.organization}
                </span>
                {user.position && (
                  <React.Fragment>
                    <br />
                    <span>
                      <b>Position:</b>
                      &nbsp;
                    {user.position === 'Other'
                      ? user.other_position
                      : user.position
                    }
                    </span>
                  </React.Fragment>
                )}
              </p>
            </List.Description>
          </List.Item>
        </List>
        <Form>
          <Form.Field>
            <label>Application Status</label>
            <Dropdown
              selection
              selectOnBlur={false}
              options={statusOptions}
              value={applicationStatus}
              onChange={onChange}
            />
            {data.status !== applicationStatus && (
              <div className="assign-status" style={{paddingTop: '1rem'}}>
                <Button onClick={onSubmit}>
                  Update status
                </Button>
              </div>
            )}
          </Form.Field>
        </Form>
      </div>
      <Divider />
      <List className="application-content">
        <List.Item>
          <List.Header>Summary of research </List.Header>
          <List.Description>{data.summary}</List.Description>
        </List.Item>
        <List.Item>
          <List.Header>Justification for restricted data </List.Header>
          <List.Description>{data.justification}</List.Description>
        </List.Item>
        {data.colleagues.length > 0 && (
          <List.Item>
            <List.Header>Additional colleagues</List.Header>
            <List.Description>
              {data.colleagues.length > 0 && (
                <List>
                  {data.colleagues.map((colleague, idx) => (
                    <List.Item key={`colleague-${idx}`}>
                      {colleague}
                    </List.Item>
                  ))}
                </List>
              )}
            </List.Description>
          </List.Item>
        )}
        <List.Item>
          <List.Header>How will you securely maintain the data and prevent unauthorized access?</List.Header>
          <List.Description>{data.security_statement}</List.Description>
        </List.Item>
      </List>
    </div>
  )
}

export default AdminReview