import React from 'react'
import {
  Table,
} from 'semantic-ui-react'

const DataTable = ({ children }) => {
  return (
    <div className="data-table">
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Date Updated</Table.HeaderCell>
            <Table.HeaderCell>Pending</Table.HeaderCell>
            <Table.HeaderCell>Total</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        { children
          ? <Table.Body>{children}</Table.Body>
          : <DataTableEmpty />
        }
      </Table>
    </div>
  )
}

const DataTableEmpty = () => (
  <Table.Body>
    <Table.Row>
      <Table.Cell>No data.</Table.Cell>
    </Table.Row>
  </Table.Body>
)

export default DataTable