import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { List, Loader } from 'semantic-ui-react'

function AdminActivity({ applicationId }) {
  const [isLoading, toggleLoading] = useState(true)
  const [activity, setActivity] = useState([])

  useEffect(() => {
    const fetchActivity = async () => {
      try {
        const response = await axios.get(
          `/api/v1/restricted_applications/${applicationId}/activity`)
        console.log('response', response)
        setActivity(response.data.data)
      } catch (err) {
        console.error('err', err)
        console.error('err.stack', err.stack)
      } finally {
        toggleLoading(false)
      }
    }
    fetchActivity()
  }, [applicationId])
  return (
    <div className="admin-activity">
      {isLoading && (
        <Loader active />
      )}
      {!isLoading && activity.length == 0 && (
        <p>No activity yet.</p>
      )}
      {!isLoading && activity.length > 0 && (
        <List divided relaxed="very">
          {activity.map((record) => (
            <List.Item key={`activity-${record._id.$oid}`}>
              <List.Content>
                <List.Header>
                  {`${record.admin.first_name} ${record.admin.last_name}`}
                </List.Header>
                <List.Description>
                  <span style={{float: "right"}}>
                    {moment(record.created_at).format('MM/DD/YYYY')}
                  </span>
                  Updated status to {record.status}.
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      )}
    </div>
  )
}

export default AdminActivity