import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Container, Grid, Header, Icon, Message } from 'semantic-ui-react'
import renderHTML from 'html-react-parser';
import Navigation from 'components/navigation'
import RestrictedData from './restricted/restricted_data'
import RestrictedViewModal from './restricted/restricted_view_modal'
import RestrictedApplicationModal from './restricted/restricted_application_modal'

const csrfToken = document.getElementsByName('csrf-token')[0].content
import { initGA, Event } from "../Tracking";

const Restricted = ({ user, currentPage, content, trackingId }) => {
  const [isLoading, toggleLoading] = useState(true)
  const [viewModal, setViewModal] = useState(null)
  const [applicationModal, setApplicationModal] = useState(null)
  const [data, setData] = useState(null)
  const [userData, setUserData] = useState({})
  const [activeApplication, setActiveApplication] = useState(null)
  const [inFlight, toggleInFlight] = useState(false)

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/v1/restricted_data')
      setData(response.data)
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    } finally {
      toggleLoading(false)
    }
  }

  const openViewModal = (id) => {
    const { surveys } = data
    const viewRecord = surveys.find((record) => {
      return record._id.$oid === id
    })
    setViewModal(viewRecord)
    if (trackingId) {
      Event('restricted', 'view', viewRecord.name)
    }
  }

  const onDownload = (filename) => {
    if (trackingId) {
      Event('restricted', 'download', filename)
    }
  }

  const openApplicationModal = (id) => {
    const { surveys } = data
    const applicationRecord = surveys.find((record) => {
      return record._id.$oid === id
    })
    const { restricted_data_applications } = userData
    const existingApplication = restricted_data_applications.find((record) => {
      return record.restricted_data_id.$oid === id
    })

    if (existingApplication) {
      setActiveApplication(existingApplication)
    }
    setApplicationModal(applicationRecord)
  }

  const onSubmit = async (formData, updateId) => {
    let axiosConfig
    if (updateId) {
      axiosConfig = {
        method: 'PUT',
        url: `/api/v1/restricted_applications/${updateId}`,
        data: formData,
        headers: {
          'X-CSRF-TOKEN': csrfToken,
        }
      }
    } else {
      axiosConfig = {
        method: 'POST',
        url: `/api/v1/restricted_applications`,
        data: formData,
        headers: {
          'X-CSRF-TOKEN': csrfToken,
        }
      }
    }
    try {
      toggleInFlight(true)
      const response = await axios(axiosConfig)
      console.log('response', response)
      setUserData(response.data.user)
      closeApplicationModal()
      toggleInFlight(false)
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
      toggleInFlight(false)
    }
  }

  const closeApplicationModal = () => {
    setApplicationModal(null)
    setActiveApplication(null)
    setInFlight(false)
  }
  const closeViewModal = () => { setViewModal(null) }

  useEffect(() => {
    fetchData()
    setUserData(user)

    // If GA is enabled in production, initialize for tracking events.
    if (trackingId) {
      initGA(trackingId)
    }
  }, [])
  const { restricted_data_applications, organization, position } = userData
  let existingApplications  = {}
  let applicationData = {}
  if (restricted_data_applications) {
    restricted_data_applications.forEach((record)=> {
      applicationData[record.restricted_data_id.$oid] = record
      existingApplications[record.restricted_data_id.$oid] = true
    })
  }

  const profileComplete = organization && position
  return (
    <div className="restricted">
       <Container className="user-page">
        <Grid>
          <Grid.Column width={4}>
            <Navigation currentPage={currentPage} />
          </Grid.Column>

          <Grid.Column width={12}>
            <div className="page--header">
              <Header as='h1'>Restricted Data</Header>
            </div>
            <div className="page--content">
              {content !== null && (
                renderHTML(content)
              )}
              {content === null && (
                <React.Fragment>
                  <p>Access to restricted AEP data is available to approved researchers for a fee. The standard cost of a restricted AEP data file is $2,000. Scholarships to offset this fee may be available. More information about the AEP Scholarship Program is available from the portal homepage.</p>
                  <p>Restricted data files may include, for example, National Center for Educational Statistics (NCES) school identifiers, more detailed curriculum title information, or other variables that have been omitted from deidentified AEP data files.</p>
                  <p>You can view information about the data files available for purchase below. Note that the data files themselves are not provided, only supplementary information such as questionnaires, codebooks, and variable lists. Access to the data will be provided after your application has been approved and payment received.</p>
                  <p>Before applying for restricted data, please review the restricted data file information pages as the application will ask you to specify the data set you are interested in purchasing.</p>
                  <p>Please note you will be required to provide proof of IRB approval and complete an AEP Data Use Agreement to receive the restricted data.</p>
                  <p>To apply for restricted AEP data access, select the “Restricted Data Application” button and complete the online application.</p>
                  <p>Scholarships are available to researchers who meet certain criteria and/or may need additional resources to be able to work with these data.</p>
                </React.Fragment>
              )}
            </div>
            {!profileComplete && <ProfileWarning />}
            { data &&
              <RestrictedData
                data={data}
                openModal={openViewModal}
                openApplication={openApplicationModal}
                existingApplications={existingApplications}
                profileComplete={profileComplete}
                applicationData={applicationData}
              />
            }
          </Grid.Column>
        </Grid>
      </Container>
      {viewModal &&
        <RestrictedViewModal
          record={viewModal}
          onClose={closeViewModal}
          handleDownload={onDownload}
        />
      }
      {applicationModal &&
        <RestrictedApplicationModal
          record={applicationModal}
          data={activeApplication}
          handleSubmit={onSubmit}
          onClose={closeApplicationModal}
          loading={inFlight}
        />
      }
    </div>
  )
}

const ProfileWarning = () => (
  <Message warning>
    <p>
      <Icon name="warning" />
      &nbsp;
      In order to apply for restricted AEP data, you must first <a href="/profile">complete your profile.</a>
    </p>
  </Message>
)

export default Restricted