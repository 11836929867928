import React, { Component } from 'react'
import { Dropdown, Segment, Form } from 'semantic-ui-react'
const options = [
  { key: 'active', text: 'Active', value: 'active' },
  { key: 'inactive', text: 'Inactive', value: 'inactive' },
  { key: 'registered', text: 'Registered', value: 'registered' }
]

class UsersFilter extends Component {
  render() {
    return (
      <Segment stacked>
        <Form>
          <Form.Field>
            <label>Status</label>
            <Dropdown placeholder='Status' fluid multiple selection options={options} />
          </Form.Field>
        </Form>
      </Segment>
    );
  }

}

export default UsersFilter
