import React, { Component } from 'react';
import { Card } from 'semantic-ui-react'
import moment from 'moment'

const SurveyGridItem = (props) => (
  <Card
    link
    onClick={() => props.showSurveyModal(props.survey._id.$oid)}
  >
    <Card.Content>
      <Card.Header>
        {props.survey.name}
      </Card.Header>
      <Card.Meta>
        {`Updated ${moment(props.survey.updated_at).format('MMM D, YYYY')}`}
      </Card.Meta>
      <Card.Description>
        {props.survey.description}
      </Card.Description>
    </Card.Content>
  </Card>
)

export default SurveyGridItem
