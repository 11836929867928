import React, { useState } from 'react'
import {
  Button,
  Icon,
  Menu,
  Visibility
} from 'semantic-ui-react'

const navigationDisplay = {
  display: 'flex',
  flexDirection: 'column',
}

const regularStyle = {
  ...navigationDisplay,
  position: 'relative',
}
const stickStyle = {
  ...navigationDisplay,
  position: 'fixed',
  top: '10px',
}

const Navigation = ({ currentPage }) => {
  const [sticky, toggleSticky] = useState(false)
  const toggleNavigationStick = (toggle) => {
    toggleSticky(toggle)
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth'})
  }

  return (
    <Visibility
      onBottomPassedReverse={() => toggleNavigationStick(false)}
      onBottomPassed={() => toggleNavigationStick(true)}
      once={false}
    >
      <div
        className="navigation--container"
        style={sticky ? stickStyle : regularStyle}
      >
        <Menu id="navigation" vertical>
          <Menu.Item
            active={currentPage === "/"}
            href="/"
            link
          >
            Home
          </Menu.Item>
          <Menu.Item
            active={currentPage === "/deidentified"}
            href="/deidentified"
            link
          >
            Deidentified Data
          </Menu.Item>
          <Menu.Item>
            Restricted Data
            <Menu.Menu className="sub-menu" style={{ textIndent: '1rem' }}>
              <Menu.Item
                  active={currentPage === "/restricted"}
                  href="/restricted"
                  link
              >
                Data Sets
              </Menu.Item>
              <Menu.Item
                  active={currentPage === "/restricted-faq"}
                  href="/restricted-faq"
                  link
              >
                FAQ
              </Menu.Item>
            </Menu.Menu>
          </Menu.Item>
        </Menu>
        { sticky
          ? (
            <Button
              circular
              icon
              onClick={scrollToTop}
              style={{ marginTop: '3rem', alignSelf: 'center' }}>
              <Icon name="double angle up" size="large" />
            </Button>
          )
          : null
        }
      </div>
    </Visibility>
  )
}

export default Navigation