import React, { Component } from 'react'
import ReactDOM from 'react-dom'

function copyStyles(sourceDoc, targetDoc) {
  Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
    if (styleSheet.cssRules) { // true for inline styles
      const newStyleEl = sourceDoc.createElement('style');

      Array.from(styleSheet.cssRules).forEach(cssRule => {
        newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
      });
      targetDoc.head.appendChild(newStyleEl);
    } else if (styleSheet.href) { // true for stylesheets loaded from a URL
      const newLinkEl = sourceDoc.createElement('link');

      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = styleSheet.href;
      targetDoc.head.appendChild(newLinkEl);
    }
  });
}

class ReviewWindow extends Component {
  constructor(props) {
    super(props)
    this.containerEl = null
    this.externalWindow = null
    this.state = {
      mounted: false,
    }
  }

  componentDidMount() {
    const {scholarship} = this.props
    this.externalWindow = window.open('', '', 'width=600,height=400,location=no,toolbar=no,status=no,menubar=no');
    this.externalWindow.document.title = `${scholarship.name} Review`
    this.containerEl = this.externalWindow.document.createElement('div');
    this.containerEl.setAttribute('style', 'padding: 2em')
    this.externalWindow.document.body.appendChild(this.containerEl);
    copyStyles(this.props.document, this.externalWindow.document);
    this.externalWindow.addEventListener('beforeunload', () => {
      this.props.onClose()
    })
    this.setState({
      mounted: true
    })
  }

  componentWillUnmount() {
    this.externalWindow.close();
  }

  render() {
    if (!this.containerEl) {
      return <React.Fragment />
    }

    return ReactDOM.createPortal(this.props.children, this.containerEl);
  }
}

export default ReviewWindow