import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Icon,
  Modal,
  Tab,
} from 'semantic-ui-react'
import AdminActivity from './activity'
import AdminReview from './review'

const csrfToken = document.getElementsByName('csrf-token')[0].content

function RestrictedApplicationAdmin ({
  restricted_data,
  open,
  onClose,
  data,
  user,
}) {
  const [initialData, setInitialData] = useState(data)
  const [applicationStatus, setApplicationStatus] = useState(0)

  useEffect(() => {
    setApplicationStatus(data.status)
  }, [])

  const handleChange = (e, { value }) => {
    setApplicationStatus(value)
  }

  const updateStatus = async () => {
    try {
      const response = await axios.put(
        `/api/v1/restricted_applications/${data._id.$oid}/status`,
        { restricted_data_application: { status: applicationStatus }},
        {
          headers: {
            'X-CSRF-TOKEN': csrfToken,
          }
        }
      )
      console.log('response', response)
      setInitialData(response.data.data)
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    }
  }

  const panes = [
    { menuItem: 'Review', render: () => (
      <Tab.Pane>
        <AdminReview
          applicationStatus={applicationStatus}
          data={initialData}
          onChange={handleChange}
          onSubmit={updateStatus}
          user={user}
        />
      </Tab.Pane>
    )},
    { menuItem: 'Activity', render: () => (
      <Tab.Pane>
        <AdminActivity
          applicationId={initialData._id.$oid}
        />
      </Tab.Pane>
    )},
  ]

  return (
    <Modal
      open={open}
      onClose={onClose}
      centered={false}
      size="small"
    >
      <Modal.Header>
        Restricted data application for {restricted_data.name}
      </Modal.Header>
      <Modal.Content>
        <Tab panes={panes} />

      </Modal.Content>
      <Modal.Actions>
        <Button
            icon
            as="a"
            labelPosition="left"
            href={`/api/v1/restricted_applications/${data._id.$oid}/download`}
            target="_blank"
            basic
        >
          <Icon name="download" />
          Download
        </Button>
        <Button onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default RestrictedApplicationAdmin