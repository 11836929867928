import React from 'react';
import { Button, Header, Icon, Modal, Table } from 'semantic-ui-react';
import moment from 'moment-timezone';

function MetricsSurveyModal({onClose, data }) {
    const { survey, downloads } = data;
    const currentTz = moment.tz.guess();
    console.log('data', data)
    console.log('downloads', downloads)
    return (
        <Modal open={true} onClose={onClose} centered={false}>
        <Modal.Header>
            {survey.name}
            <Header.Subheader>Metrics</Header.Subheader>
        </Modal.Header>
        <Modal.Content>
            <Table celled compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>File name</Table.HeaderCell>
                        <Table.HeaderCell>Count</Table.HeaderCell>
                        <Table.HeaderCell>Last downloaded</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                {downloads && downloads.map((download) => (
                    <Table.Row key={`file-${download._id.file_id}-downloads`}>
                        <Table.Cell>{download._id.file_name}</Table.Cell>
                        <Table.Cell>{download.count}</Table.Cell>
                        <Table.Cell>{moment(download.most_recent).tz(currentTz).format('MM/DD/YYYY LTS z')}</Table.Cell>
                    </Table.Row>
                ))}
                {downloads.length === 0 && (
                    <Table.Row>
                        <Table.Cell colSpan={3} textAlign="center">
                            <Icon name="cancel" size="big" />
                            <Header>No downloads logged yet</Header>
                        </Table.Cell>
                    </Table.Row>
                )}
                </Table.Body>
            </Table>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={onClose}>Close</Button>
        </Modal.Actions>
      </Modal>
    );
}

export default MetricsSurveyModal;