import React from 'react'
import moment from 'moment'
import { Dropdown, Label, Table } from 'semantic-ui-react'

function ScholarshipsTable({ data, onEdit, onDelete }) {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Active</Table.HeaderCell>
          <Table.HeaderCell>Accepting applications</Table.HeaderCell>
          <Table.HeaderCell>Last updated</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data && data.map((record) => (
          <Table.Row key={`scholarship-${record._id.$oid}`}>
            <Table.Cell>{record.name}</Table.Cell>
            <Table.Cell>
              {record.active
                ? <Label color="green">Active</Label>
                : <Label>Inactive</Label>
              }
            </Table.Cell>
            <Table.Cell>
              {record.accepting_applications
                ? <Label color="green">Yes</Label>
                : <Label>No</Label>
              }
            </Table.Cell>
            <Table.Cell>
              {moment(record.updated_at).format('MM/DD/YYYY')}
            </Table.Cell>
            <Table.Cell width={2}>
              <Dropdown
                inline
                icon='ellipsis horizontal'
                className='link item'
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    as="a"
                    icon='file'
                    text='Applications'
                    href={`/scholarships/${record._id.$oid}`}
                  />
                  <Dropdown.Item
                    icon='edit'
                    text='Edit'
                    onClick={() => onEdit(record)}
                  />
                  <Dropdown.Divider />
                  <Dropdown.Item
                    icon='cancel'
                    onClick={() => onDelete(record._id.$oid)}
                    text='Delete'
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

export default ScholarshipsTable