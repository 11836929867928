import React, { Component } from 'react';
import { Container, Menu } from 'semantic-ui-react'

class AdminHeader extends Component {

  render() {
    return (
      <Menu
        borderless
        id='admin-menu'
        inverted
      >
        <Container>
          <Menu.Item
            as='a'
            href='/'
          >
            Home
          </Menu.Item>
          <Menu.Item
            as='a'
            href='/admin'
          >
            Admins
          </Menu.Item>
          <Menu.Item as="a" href="/faq">
            FAQ
          </Menu.Item>
          <Menu.Item
            as='a'
            href='/surveys'
          >
            Surveys
          </Menu.Item>
          <Menu.Item
            as='a'
            href='/restricted_data'
          >
            Restricted Data
          </Menu.Item>
          <Menu.Item
            as='a'
            href='/scholarships'
          >
            Scholarships
          </Menu.Item>
          <Menu.Item
            as='a'
            href='/users'
          >
            Users
          </Menu.Item>
        </Container>
      </Menu>
    );
  }

}

export default AdminHeader;
