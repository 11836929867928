export const getOrgType = (orgInput) => {
  switch (orgInput) {
    case 'federal':
      return 'Federal Government'
    case 'state':
      return 'State Government'
    case 'local':
      return 'Local Government'
    case 'college':
      return 'College/University'
    case 'school':
      return 'Primary/Secondary School'
    case 'other_research':
      return 'Other Research Institution'
    case 'philanthropic':
      return 'Philanthropic Organization'
    case 'consulting':
      return 'Consulting Firm'
    default:
      return 'Other'
  }
}

export const getIntendedUse = (intendedUse) => {
  switch(intendedUse) {
    case 'advocacy':
      return 'Advocacy'
    case 'general_information':
      return 'General Information'
    case 'policy':
      return 'Policy Action'
    case 'proposal':
      return 'Proposal'
    case 'research':
      return 'Conduct Research'
    case 'media':
      return 'Media Release'
    case 'report':
      return 'Prepare Report'
    default:
      return 'Other'
  }
}

export const fileTypes = [
  { text: 'CSV Data', value: 'csv' },
  { text: 'Stata Data', value: 'stata' },
  { text: 'Codebook', value: 'codebook' },
  { text: 'Technical Document', value: 'technical_document' },
  { text: 'Questionnaire', value: 'questionnaire' },
  { text: 'Uploaded File', value: 'file' }
]

export const applicationStatus = {
  0: 'Pending',
  1: 'Denied',
  2: 'Granted',
  3: 'Awaiting Payment',
  4: 'Fulfilled'
}

export const cvMimeTypes = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
]

export const ethnicityOptions = [
  {
    key: 'black-or-african-american',
    value: 'Black or African American',
    text: 'Black or African American',
  },
  {
    key: 'latinx',
    value: 'Latinx',
    text: 'Latinx',
  },
  {
    key: 'american-indian-or-alaska-native',
    value: 'American Indian or Alaska Native',
    text: 'American Indian or Alaska Native',
  },
  {
    key: 'asian',
    value: 'Asian',
    text: 'Asian',
  },
  {
    key: 'native-hawaiian-or-other-pacific-islander',
    value: 'Native Hawaiian or Other Pacific Islander',
    text: 'Native Hawaiian or Other Pacific Islander',
  },
  {
    key: 'white',
    value: 'White',
    text: 'White',
  },
  {
    key: 'other',
    value: 'Other',
    text: 'Other',
  },
]