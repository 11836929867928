import React from 'react'
import { Modal } from 'semantic-ui-react'
import SurveyForm from 'components/modals/surveys/survey_form'

import RestrictedDataContext from './context'

const RestrictedModal = () => {
  return (
    <RestrictedDataContext.Consumer>
      {({ open, mode, handleClose, handleSubmit, data }) => {
        const modalHeader = mode === 'new'
        ? 'Create Restricted Data'
        : 'Edit Restricted Data'
        return (
          <Modal
            open={open}
            onClose={handleClose}
            centered={false}
          >
            <Modal.Header>{modalHeader}</Modal.Header>
            <Modal.Content>
              <SurveyForm
                data={data}
                onSubmit={handleSubmit}
              />
            </Modal.Content>
          </Modal>
        )
      }}
    </RestrictedDataContext.Consumer>
  )
}

export default RestrictedModal