import React from 'react'
import { Card, Container, Grid, Header } from 'semantic-ui-react'
import Navigation from 'components/navigation'

const Landing = ({ currentPage }) => {
  return (
    <Container className="user-page">
      <Grid>
        <Grid.Column width={4}>
          <Navigation currentPage={currentPage} />
        </Grid.Column>

        <Grid.Column width={12}>
          <div className="page--header">
            <Header as='h1'>Welcome to the AEP Data Portal!</Header>
          </div>
          <div className="page--content">
            <p>
              The AEP data portal provides information about, and access to, deidentified survey data files for several studies conducted with the RAND American Educator Panels. The portal also provides information and application materials for restricted data files. Please click the links below to find out more about what is available.
            </p>
            <p>
              For questions and comments, please email us at <a href="mailto:aepdata@rand.org">AEPdata@rand.org</a>
            </p>
            <Card.Group itemsPerRow={2} stackable>
              <Card link href="/deidentified">
                <Card.Content>
                  <Card.Header>Deidentified Data</Card.Header>
                  <Card.Description>
                    Data from AEP studies that are available at no-cost; some variables have been suppressed.
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card link href="/restricted">
                <Card.Content>
                  <Card.Header>Restricted Data</Card.Header>
                  <Card.Description>
                    You may apply to access detailed data sets that include variables suppressed from the deidentified data sets and additional variables about schools, etc. The standard cost of a restricted AEP data file is $2,000.
                  </Card.Description>
                </Card.Content>
              </Card>
            </Card.Group>
          </div>
          <div className="bento-callout">
            <Header as='h2'>Bento – Another resource to access AEP data!</Header>
            <p>
              To view AEP data in easy-to-understand charts, sign up for a Bento account <a href="http://www.bentobento.info/" target="_blank" rel="noreferrer">here</a>. <a href="http://www.getbento.info/" target="_blank" rel="noreferrer">Bento</a> is a free online data visualization tool that allows you to filter and segment survey results by school characteristics or educator background, compare data to state and national averages, and export visualizations for reports.
            </p>
          </div>
        </Grid.Column>
      </Grid>
    </Container>
  )
}

export default Landing