import React from 'react'
import moment from 'moment-timezone'
import get from 'lodash/get'
import { Header, Label, Table } from 'semantic-ui-react'

const applicationStatus = {
  0: 'Pending',
  1: 'Denied',
  2: 'Granted',
  3: 'Edits requested',
  4: 'Edits submitted',
}

function ScholarshipApplicationTable ({ data, onClick }) {
  const currentTz = moment.tz.guess()

  return (
    <div className="scholarship-application-table">
      <Header>Applications</Header>
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Applicant</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Date submitted</Table.HeaderCell>
            <Table.HeaderCell>Last updated</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={5} textAlign="center">
                No applications
              </Table.Cell>
            </Table.Row>
          )}
          {data.length > 0 && data.map((record) => {
            const { user } = record
            let createdAt = get(record, 'created_at', null)
            let submittedAt = get(record, 'submitted_at', null)

            let displayTime
            if (submittedAt) {
              displayTime = moment(submittedAt).tz(currentTz).format('MM/DD/YYYY')
            } else {
              displayTime = moment(createdAt).tz(currentTz).format('MM/DD/YYYY')
            }


            return (
              <Table.Row
                key={`scholarship-application-${record._id.$oid}`}
                onClick={() => onClick(record)}
              >
                <Table.Cell>
                  {`${user.first_name} ${user.last_name}`}
                </Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>
                  {displayTime}
                </Table.Cell>
                <Table.Cell>
                  {moment(record.updated_at).tz(currentTz).format('MM/DD/YYYY')}
                </Table.Cell>
                <Table.Cell>
                  <Label>{applicationStatus[get(record, 'status', 0)]}</Label>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </div>
  )
}

export default ScholarshipApplicationTable