import React, { useEffect, useState } from 'react'
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Icon,
  List,
  Header,
} from 'semantic-ui-react'
import get from 'lodash/get'
import moment from 'moment-timezone'
import ReviewContent from './review_content'
import ReviewWindow from './review_window'

// # Pending - 0
// # Denied - 1
// # Granted - 2
// # Awaiting Payment - 3
// # Fulfilled - 4

const statusOptions = [
  {key: 'pending', value: 0, text: 'Pending'},
  {key: 'denied', value: 1, text: 'Denied'},
  {key: 'granted', value: 2, text: 'Granted'},
  {key: 'request-edits', value: 3, text: 'Request edits'},
  {key: 'edits-submitted', value: 4, text: 'Edits submitted', disabled: true},
]

function ScholarshipApplicationReview({
  data,
  onSubmit,
  scholarship,
}) {
  const currentTz = moment.tz.guess()
  const [displayReviewWindow, toggleReviewWindow] = useState(false)
  const [applicationStatus, updateApplicationStatus] = useState(data.status)
  const handleChange = (e, { value }) => {
    updateApplicationStatus(value)
  }

  const handleSubmit = () => { onSubmit(data._id.$oid, applicationStatus) }

  const { user } = data
  let createdAt = get(data, 'created_at', null)
  let submittedAt = get(data, 'submitted_at', null)

  let displayTime
  if (submittedAt) {
    displayTime = moment(submittedAt).tz(currentTz).format('MM/DD/YYYY')
  } else {
    displayTime = moment(createdAt).tz(currentTz).format('MM/DD/YYYY')
  }

  return (
    <div className="restricted-application-review">
      <Button icon floated="right" onClick={() => toggleReviewWindow(true)}>
        <Icon name="print" />
        Print preview
      </Button>
      <div className="applicant">
        <List horizontal>
          <List.Item>
            <List.Header>Submitted at: </List.Header>
            <List.Description>{displayTime}</List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Last updated: </List.Header>
            <List.Description>{moment(data.updated_at).tz(currentTz).format('MM/DD/YYY')}</List.Description>
          </List.Item>
        </List>
        <List>
          <List.Item>
            <List.Header>Applicant</List.Header>
            <List.Description>
              <p>
                {`${user.first_name} ${user.last_name}`}
                &nbsp;
                ({user.email})
                &nbsp;
                <a
                  href={`/api/v1/restricted_applications/${user._id.$oid}/cv`}
                  target="_blank"
                >
                  <Icon name="file" />
                  CV
                </a>
                <br />
                <span>
                  <b>Organization:</b> {user.organization}
                </span>
                {user.position && (
                  <React.Fragment>
                    <br />
                    <span>
                      <b>Position:</b>
                      &nbsp;
                    {user.position === 'Other'
                      ? user.other_position
                      : user.position
                    }
                    </span>
                  </React.Fragment>
                )}
              </p>
            </List.Description>
          </List.Item>
        </List>
        <Form>
          <Form.Field>
            <label>Application Status</label>
            <Dropdown
              selection
              selectOnBlur={false}
              options={statusOptions}
              value={applicationStatus}
              onChange={handleChange}
            />
            {data.status !== applicationStatus && (
              <div className="assign-status" style={{paddingTop: '1rem'}}>
                <Button
                  onClick={handleSubmit}
                >
                  Update status
                </Button>
              </div>
            )}
          </Form.Field>
        </Form>
      </div>
      <Divider />
      <ReviewContent data={data}/>

      {displayReviewWindow && (
        <ReviewWindow
          document={document}
          onClose={() => toggleReviewWindow(false)}
          scholarship={scholarship}
        >
          <Header>{scholarship.name} application</Header>
          <List horizontal>
            <List.Item>
              <List.Header>Submitted at: </List.Header>
              <List.Description>{displayTime}</List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Last updated: </List.Header>
              <List.Description>{moment(data.updated_at).tz(currentTz).format('MM/DD/YYY')}</List.Description>
            </List.Item>
          </List>
          <List>
            <List.Item>
              <List.Header>Applicant</List.Header>
              <List.Description>
                <p>
                  {`${user.first_name} ${user.last_name}`}
                  &nbsp;
                  ({user.email})
                  <br />
                  <span>
                    <b>Organization:</b> {user.organization}
                  </span>
                  {user.position && (
                    <React.Fragment>
                      <br />
                      <span>
                        <b>Position:</b>
                        &nbsp;
                      {user.position === 'Other'
                        ? user.other_position
                        : user.position
                      }
                      </span>
                    </React.Fragment>
                  )}
                </p>
              </List.Description>
            </List.Item>
          </List>
          <Divider />
          <ReviewContent data={data}/>
        </ReviewWindow>
      )}
    </div>
  )
}

export default ScholarshipApplicationReview