import React, { Component } from 'react';
import { Header, Modal } from 'semantic-ui-react'
import axios from 'axios'

import SurveyForm from './survey_form'

class EditSurveyModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
    }

    this.updateSurvey = this.updateSurvey.bind(this)
  }

  updateSurvey(formData, surveyId) {
    const csrfToken = document.getElementsByName('csrf-token')[0].content
    this.setState({
      isLoading: true,
    }, () => {
      axios.put(`/api/v1/surveys/${surveyId}`, formData
      , {
        headers: {
          'X-CSRF-TOKEN': csrfToken,
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        console.log('response', response)
        this.props.onClose(true)
      }).catch((err) => {
        this.setState({
          isLoading: false
        })
      })
    })
  }

  render() {
    return (
      <Modal
        open={true}
        onClose={this.props.onClose}
        centered={false}
      >
        <Modal.Header>Edit Survey</Modal.Header>
        <Modal.Content>
          <SurveyForm
            data={this.props.data}
            isLoading={this.state.isLoading}
            onSubmit={this.updateSurvey} />
        </Modal.Content>
      </Modal>
    );
  }

}

export default EditSurveyModal;
