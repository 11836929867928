import _ from 'lodash'
import React, { Component } from 'react'
import UserRow from './user_row'

import { Header, Icon, Table } from 'semantic-ui-react'

class UsersTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      column: null,
      data: props.users,
      direction: null
    }

    this.handleSort = this.handleSort.bind(this)
  }

  handleSort(clickedColumn) {
    const { column, data, direction } = this.state

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: 'ascending'
      })
      return
    }

    this.setState( {
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending'
    })
  }

  renderUserRows() {
    const { data } = this.state
    const { showUserModal } = this.props

    if (data.length > 0) {
      return data.map((user, idx) => {
        return (
          <UserRow key={`user-${idx}`} user={user} showUserModal={showUserModal} />
        )
      })
    } else {
      return(
        <Table.Row>
          <Table.Cell colSpan="4" textAlign="center" verticalAlign="middle">
            <Icon name="user outline" size="big" /><br />
            No Users
          </Table.Cell>
        </Table.Row>
      )
    }
  }

  render() {
    const { column, direction } = this.state

    return (
      <div>
        <Table celled sortable fixed selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'first_name' ? direction : null}
                onClick={() => this.handleSort('first_name')}
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'email' ? direction : null}
                onClick={() => this.handleSort('email')}
              >
                Email
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'created_at' ? direction : null}
                onClick={() => this.handleSort('created_at')}
              >
                Date Registered
              </Table.HeaderCell>

              <Table.HeaderCell>
                Status
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.renderUserRows()}
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default UsersTable
