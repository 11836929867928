import React, { Component } from 'react'
import axios from 'axios'
import { Button, Container, Form, Header, Icon, Input, Pagination, Segment } from 'semantic-ui-react'

import UsersFilter from './users_filter'
import UsersTable from './users_table'
import ModalContainer from '../modals/modal_container'

import { randomKeyString } from '../utils'

let searchTimeout = null

class Users extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activePage: 1,
      isLoading: true,
      query: '',
      users: [],
      userCount: null,
      key: null,
      activeUser: null,
      creatingUser: false
    }

    this.clearSearch = this.clearSearch.bind(this)
    this.handleAccess = this.handleAccess.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.onCloseUserModal = this.onCloseUserModal.bind(this)
    this.onCloseCreateUserModal = this.onCloseCreateUserModal.bind(this)
    this.showCreateUserModal = this.showCreateUserModal.bind(this)
    this.showUserModal = this.showUserModal.bind(this)
  }

  getUsers(activePage) {
    const setPage = activePage === undefined ? 1 : activePage
    const usersUrl = activePage === undefined ? '/api/v1/users.json' : `/api/v1/users.json?page=${activePage}`

    const newKey = randomKeyString(7)
    axios.get(usersUrl)
      .then((response) => {
        this.setState({
          activePage: setPage,
          isLoading: false,
          users: response.data.users,
          userCount: response.data.user_count,
          key: `users-page-${newKey}`
        })
      })
  }

  searchUsers = (activePage) => {
    const { query } = this.state
    const setPage = activePage === undefined ? 1 : activePage

    if (query === '') {
      this.getUsers()
      return
    }
    const newKey = randomKeyString(7)

    axios.get(`/api/v1/users/search?query=${query}`)
      .then((response) => {
        this.setState({
          activePage: setPage,
          isLoading: false,
          users: response.data.users,
          userCount: response.data.user_count,
          key: `users-search-page-${newKey}`
        })
      })
  }

  componentDidMount() {
    this.getUsers()
  }

  handlePageChange(e, { activePage }) {
    this.setState({
      isLoading: true
    }, () => {
      this.getUsers(activePage)
    })
  }

  handleSearchChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  handleAccess(userId, grantType, reason) {
    const csrfToken = document.getElementsByName('csrf-token')[0].content

    const userObject = {
      user: {
        id: userId,
        grant_type: grantType
      }
    }

    console.log('reason', reason)

    if (reason !== undefined) {
      userObject['user']['reason'] = reason
    }

    console.log('userObject', userObject)

    axios.put('/api/v1/users/access', userObject, {
      headers: {
        'X-CSRF-TOKEN': csrfToken
      }
    })
      .then((response) => {
        console.log('response', response)
        this.setState({
          activeUser: response.data.user
        }, () => {
          if (this.state.query.trim() == '') {
            this.getUsers(this.state.activePage)
          } else {
            this.searchUsers(this.state.activePage)
          }
        })
      })
      .catch((err) => {
        console.log('err', err)
        console.log('err.response', err.response)
      })
  }

  handleEmailUpdate = (id, email) => {
    const csrfToken = document.getElementsByName('csrf-token')[0].content

    const userObject = {
      user: {
        email,
      }
    }
    axios.put(`/api/v1/users/${id}`, userObject, {
      headers: {
        'X-CSRF-TOKEN': csrfToken
      }
    })
      .then((response) => {
        console.log('response', response)
        this.setState({
          activeUser: response.data.user
        }, () => {
          if (this.state.query.trim() == '') {
            this.getUsers(this.state.activePage)
          } else {
            this.searchUsers(this.state.activePage)
          }
        })
      })
      .catch((err) => {
        console.log('err', err)
        console.log('err.response', err.response)
      })
  }

  clearSearch(e) {
    this.setState({
      query: '',
    }, () => this.getUsers())
  }

  showSearch() {
    const { activePage, isLoading, query, users, userCount } = this.state

    return (
      <Input
        type="text"
        onChange={this.handleSearchChange}
        onKeyPress={this.handleSearchKeyPress}
        placeholder='Search for a user by name or email'
        action={{ icon: 'search', onClick: this.searchUsers }}
        value={query}
        fluid
      />
    )
  }

  handleSearchKeyPress = (e) => {
    if (e.charCode == 13) {
      this.searchUsers()
    }
  }


  showPagination() {
    const { activePage, isLoading, users, userCount } = this.state
    if (users.length > 0) {
      return (
        <div className='aep-admin__pagination'>
          <Pagination
            activePage={activePage}
            secondary
            totalPages={Math.ceil(userCount / 10)}
            onPageChange={this.handlePageChange}
          />
        </div>
      )
    }
  }

  showUserModal(userId) {
    axios.get(`/api/v1/users/${userId}`)
      .then((response) => {
        this.setState({
          activeUser: response.data.user
        })
      })
  }

  showCreateUserModal() {
    this.setState({
      creatingUser: true
    })
  }

  onCloseUserModal() {
    this.setState({
      activeUser: null
    })
  }

  onCloseCreateUserModal(userCreated) {
    this.setState({
      creatingUser: false
    }, () => {
      if (userCreated) {
        this.getUsers()
      }
    })
  }

  render() {
    const { activeUser, creatingUser } = this.state

    return(
      <div id="users" className='aep-admin__container'>
        <Container>
          <div className='aep-admin__header'>
            <div className='aep-admin__buttons right'>
              <Button
                as='a'
                icon
                labelPosition='left'
                style={{ 'marginBottom': '1em' }}
                target='_blank'
                href='/api/v1/users.csv'
              >
                <Icon name='download' />
                Download Users
              </Button>

              <Button
                icon
                labelPosition='left'
                style={{ 'marginBottom': '1em' }}
                onClick={() => this.showCreateUserModal()}
              >
                <Icon name='add user' />
                Create New User
              </Button>
            </div>

            <Header as='h1'>Users</Header>
          </div>

          <div className='aep-admin__search'>
            <Segment clearing>
              {this.showSearch()}
            </Segment>
          </div>

          <Segment textAlign='center'>
            { this.state.isLoading ? <i className="fas fa-circle-notch fa-spin"></i> : <UsersTable users={this.state.users} showUserModal={this.showUserModal} key={this.state.key}/> }

            {this.showPagination()}
          </Segment>
        </Container>

        { activeUser ?
          <ModalContainer
            type="UserModal"
            data={activeUser}
            onClose={this.onCloseUserModal}
            handleAccess={this.handleAccess}
            handleEmailUpdate={this.handleEmailUpdate}
          /> :
          null }

        { creatingUser ?
          <ModalContainer
            type="CreateUserModal"
            data={true}
            onClose={this.onCloseCreateUserModal}
          /> :
          null }

      </div>
    )
  }
}

export default Users
