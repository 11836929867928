import React, { Component } from 'react'
import { Button, Divider, Form, Header, Icon, Input, Label, List, Modal, Radio, Table } from 'semantic-ui-react'
import axios from 'axios'

class CreateUserModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      org_type: '',
      receive_announcments: '1',
      errors: {},
      isSubmitting: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(e) {
    let changes = {}
    changes[e.target.name] = e.target.value
    this.setState(changes)
  }

  invalidForm() {
    const fields = [
      'first_name',
      'last_name',
      'email',
      'address',
      'city',
      'state',
      'zip',
      'org_type',
      'receive_announcments'
    ]

    let errors = {}

    fields.forEach((field) => {
      if (this.state[field].trim() == '') {
        errors[field] = true
      }
    })

    if (/.+@(gmail|hotmail|outlook|yahoo)\.com/i.test(this.state.email)) {
      errors['email'] = true
    }

    this.setState({ errors })

    if (Object.keys(errors).length > 0) {
      return true
    }

    return false
  }

  handleSubmit(e) {
    if (this.invalidForm()) {
      return
    }
    const csrfToken = document.getElementsByName('csrf-token')[0].content

    const {
      first_name,
      last_name,
      email,
      address,
      address2,
      city,
      state,
      zip,
      org_type,
      receive_announcments,
    } = this.state

    this.setState({
      isSubmitting: true,
    }, () => {
      axios.post('/api/v1/users', {
        user: {
          first_name,
          last_name,
          email,
          address,
          address2,
          city,
          state,
          zip,
          org_type,
          receive_announcments
        }
      }, {
        headers: {
          'X-CSRF-TOKEN': csrfToken
        }
      })
        .then((response) => {
          console.log('response', response)
          this.props.onClose(true)
        })
        .catch((err) => {
          console.log('err', err)
          console.log('err.response', err.response)
          this.handleSubmissionErrors(err.response.data.errors)
        })
    })
  }

  handleSubmissionErrors(errors) {
    const errorKeys = Object.keys(errors)

    let submissionErrors = {}

    errorKeys.forEach((key) => {
      submissionErrors[key] = errors[key].join(', ')
    })

    console.log('submissionErrors', submissionErrors)

    this.setState({ errors: submissionErrors, isSubmitting: false })
  }

  render() {
    const {
      first_name,
      last_name,
      email,
      address,
      address2,
      city,
      state,
      zip,
      org_type,
      receive_announcments,
      errors
    } = this.state

    console.log('this.state', this.state)

    return (
      <Modal open={true} onClose={this.props.onClose}>
        <Modal.Header>Create New User</Modal.Header>
        <Modal.Content>
          <p>Please note, by creating a user as an administrator the standard registration process will be bypassed and the user will automatically be granted access and receive notifications.</p>
          <Modal.Description>
            <Form>
              <Form.Group>
                <Form.Input
                  label="First Name"
                  name="first_name"
                  width={8}
                  onChange={this.handleChange}
                  error={errors['first_name']}
                />
                <Form.Input
                  label="Last Name"
                  name="last_name"
                  width={8}
                  onChange={this.handleChange}
                  error={errors['last_name']}
                />
              </Form.Group>

              <Form.Input
                label="Email Address"
                name="email"
                type="email"
                onChange={this.handleChange}
                error={'email' in errors}
              />
              <small className="form-text text-muted">
                { typeof(errors['email']) === 'string' ? `Email ${errors['email']}` : 'Email address must be an institutionally affiliated email address; Gmail, Yahoo, Hotmail, etc. are not permitted.'}
              </small>

              <Divider />

              <h3>Mailing address</h3>
              <Form.Input
                label="Address"
                name="address"
                onChange={this.handleChange}
                error={errors['address']}
              />

              <Form.Field>
                <label>Address 2 (Optional)</label>
                <Input
                  name="address2"
                  onChange={this.handleChange}
                />
              </Form.Field>


              <Form.Group>
                <Form.Input
                  label="City"
                  name="city"
                  width={8}
                  onChange={this.handleChange}
                    error={errors['city']}
                />
                <Form.Input
                  label="State"
                  name="state"
                  width={4}
                  onChange={this.handleChange}
                  error={errors['state']}
                />
                <Form.Input
                  label="Zip"
                  name="zip"
                  width={4}
                  onChange={this.handleChange}
                  error={errors['zip']}
                />
              </Form.Group>

              <Divider />

              <div className={errors['org_type'] ? "field error" : "field"}>
                <label htmlFor="user_org_type">Organization Type</label>
                <select
                  name="org_type"
                  onChange={this.handleChange}
                  className="ui fluid dropdown"
                >
                  <option value={null}>Please select the type of organization you are affiliated with</option>

                  <optgroup label="Government">
                    <option value="federal">Federal</option>
                    <option value="state">State</option>
                    <option value="local">Local</option>
                  </optgroup>
                  <optgroup label="Educational or Research Institution">
                    <option value="college">College/University</option>
                    <option value="school">Primary/Secondary School</option>
                    <option value="other_research">Other Research Institution</option>
                  </optgroup>
                  <optgroup label="Other">
                    <option value="philanthropic">Philanthropic Organization</option>
                    <option value="consulting">Consulting Firm</option>
                    <option value="other">Other</option>
                  </optgroup>
                </select>
              </div>

              <Button
                positive
                onClick={() => this.handleSubmit()}
                disabled={this.state.isSubmitting}
              >
                Create User
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default CreateUserModal
