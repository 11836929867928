export const wordCounts = {
  find_out: 0,
  fit: 0,
  preferred_stat_platform: 0,
  summary: 0,
  data_interest: 0,
  justification: 0,
  security_statement: 0,
}

export const wordLimits = {
  find_out: 180,
  fit: 300,
  preferred_stat_platform: 180,
  summary: 300,
  data_interest: 10,
  justification: 300,
  security_statement: 300,
}