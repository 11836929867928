import React, { Component } from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

import axios from 'axios'

class DeleteSurveyModal extends Component {
  constructor(props) {
    super(props)

    this.deleteSurvey = this.deleteSurvey.bind(this)
  }

  deleteSurvey() {
    const csrfToken = document.getElementsByName('csrf-token')[0].content

    axios.delete(`/api/v1/surveys/${this.props.data._id.$oid}`, {
      headers: {
        'X-CSRF-TOKEN': csrfToken,
      }
    })
      .then((response) => {
        console.log('response', response)
        this.props.onClose(true)
      })
      .catch((err) => {
        console.error('err', err)
        console.error('err.stack', err.stack)
      })
  }

  render() {
    return (
      <Modal
        open={true}
        onClose={this.props.onClose}
        size='small'
      >
        <Modal.Header>
          Are you sure?
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>This action <strong>cannot</strong> be undone. This will permanently delete the <strong>{this.props.data.name}</strong> survey and remove all associated files.</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={this.deleteSurvey}>
            I understand the consequences, delete this survey
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

}

export default DeleteSurveyModal;
