import _ from 'lodash'
import axios from 'axios'
import React, { Component } from 'react'
import { Divider, Form, Input, Label, List, Message, Radio } from 'semantic-ui-react'
import ModalContainer from '../modals/modal_container'

const positionOptions = [
  {
    key: 'faculty',
    text: 'Faculty',
    value: 'Faculty',
  },
  {
    key: 'postdoc',
    text: 'Post-doc/Fellow',
    value: 'Post-doc/Fellow',
  },
  {
    key: 'principal',
    text: 'Principal/Teacher',
    value: 'Principal/Teacher',
  },
  {
    key: 'staff',
    text: 'Staff',
    value: 'Staff',
  },
  {
    key: 'student',
    text: 'Student',
    value: 'Student',
  },
  {
    key: 'other',
    text: 'Other',
    value: 'Other',
  },
]

const EMAIL_REGEX = /^[\w+\-.]+@[a-z\d\-]+(\.[a-z\d\-]+)*\.[a-z]+$/i
const ALLOWED_DOMAINS = /.+@(gmail|hotmail|outlook|yahoo|me|mac|aol|inbox|icloud|zoho)\.com/i
let checkTimeout = null

class Registration extends Component {
  constructor(props) {
    super(props)

    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      organization: '',
      department: '',
      position: '',
      other_position: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      org_type: '',
      intended_use: '',
      receive_announcments: '',
      errors: {},
      checkingEmail: false,
      showTerms: false,
      termsAccepted: false,
      isSubmitting: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onCloseTermsModal = this.onCloseTermsModal.bind(this)
  }

  handleChange(e) {
    let changes = {}
    if (e.target.id == 'receive_yes' || e.target.id == 'receive_no') {
      changes['receive_announcments'] = e.target.value
    } else if (e.target.id === 'position') {
      if (e.target.value !== 'Other') {
        this.setState({
          other_position: '',
        })
      }
      changes[e.target.id] = e.target.value
    } else {
      changes[e.target.id] = e.target.value
    }
    if (e.target.id === 'email') {
      this.setState({
        checkingEmail: true,
      }, () => this.checkEmail())
    }
    this.setState(changes)
  }

  checkEmail = _.debounce(async () => {
    try {
      const { email } = this.state
      if (!EMAIL_REGEX.test(email) || ALLOWED_DOMAINS.test(email)) {
        console.log('!EMAIL_REGEX.test(email)', !EMAIL_REGEX.test(email))
        console.log('ALLOWED_DOMAINS.test(email)', ALLOWED_DOMAINS.test(email))
        this.setState({
          errors: { ...this.state.errors, email: true }
        })
        return
      }
      const metaTags = Array.from(document.getElementsByTagName('META'))
      const csrfToken = metaTags.find((tag) => tag.name === 'csrf-token')
      const response = await axios.post('/register/check_email', {
        value: email,
      },
      {
        headers: {
          'X-CSRF-Token': csrfToken.getAttribute('content'),
        }
      },
      )
      this.setState({
        errors: { ...this.state.errors, email: false }
      })
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
      this.setState({
        errors: { ...this.state.errors, email: true }
      })
    } finally {
      this.setState({
        checkingEmail: false,
      })
    }
  }, 1500)

  invalidForm() {
    const fields = [
      'first_name',
      'last_name',
      'organization',
      'position',
      'email',
      'address',
      'city',
      'state',
      'zip',
      'org_type',
      'intended_use',
      'receive_announcments'
    ]

    let errors = {}

    fields.forEach((field) => {
      if (this.state[field].trim() == '') {
        errors[field] = true
      }
    })

    if (ALLOWED_DOMAINS.test(this.state.email)) {
      errors['email'] = true
    }

    const { position, other_position } = this.state

    if (position === 'Other' && other_position.trim() === '') {
      errors['position'] = true
      errors['other_position'] = true
    }

    this.setState({ errors })

    if (Object.keys(errors).length > 0) {
      return true
    }

    return false
  }

  handleSubmit(e) {
    if (this.invalidForm()) {
      e.preventDefault()
    } else {
      e.preventDefault()
      this.setState({
        showTerms: true
      })
    }
  }

  renderErrors(errorMessages) {
    if (Object.keys(errorMessages).length > 0) {
      const errorItems = Object.keys(errorMessages).map((key, idx) => {
        const message = errorMessages[key]
        return (
          <List.Item key={`registration-err-${idx}`}>
            {`${key} ${message}`}
          </List.Item>
        )
      })
      return (
        <Message negative>
          <Message.Header>There were some errors with your registration</Message.Header>
          <List bulleted>
            {errorItems}
          </List>
        </Message>
      )
    }
  }

  onCloseTermsModal(termsAccepted) {
    this.setState({
      showTerms: false,
      termsAccepted,
      isSubmitting: termsAccepted
    }, () => {
      if (termsAccepted) {
        document.getElementById('new_user').submit()
      }
    })
  }

  render() {
    const {
      first_name,
      last_name,
      email,
      address,
      address2,
      city,
      state,
      zip,
      org_type,
      intended_use,
      receive_announcments,
      errors,
      showTerms,
      position,
      other_position,
      organization,
      department,
      checkingEmail,
    } = this.state

    const errorMessages = this.props.errors

    let emailIcon
    if (email === '') {
      emailIcon = {}
    } else {
      if (errors['email']) {
        emailIcon = { name: 'cancel', color: 'red' }
      } else {
        emailIcon = { name: 'check', color: 'green'}
      }
    }

    return (
      <div className='ui form'>
          <div className='ui container'>
          {this.renderErrors(errorMessages)}
          <Form.Group>
            <Form.Input
              label="First Name"
              id="first_name"
              name="user[first_name]"
              width={8}
              onChange={this.handleChange}
              error={errors['first_name']}
              value={first_name}
            />
            <Form.Input
              label="Last Name"
              id="last_name"
              name="user[last_name]"
              width={8}
              onChange={this.handleChange}
              error={errors['last_name']}
              value={last_name}
            />
          </Form.Group>

          <Form.Field>
            <label>Email Address</label>
            <Input
              id="email"
              type="email"
              name="user[email]"
              onChange={this.handleChange}
              error={errors['email']}
              value={email}
              loading={checkingEmail}
              icon={emailIcon}
            />
            {
              errors['email']
              ? (
                <Label basic color='red' pointing>
                  Email is invalid or already taken
                </Label>
              )
              : null
            }
            <small className="form-text text-muted">
              Email address must be an institutionally affiliated email address; Gmail, Yahoo, Hotmail, etc. are not permitted.
            </small>
          </Form.Field>

          <Form.Group>
            <Form.Input
              label="Organization"
              id="organization"
              name="user[organization]"
              width={8}
              onChange={this.handleChange}
              error={errors['organization']}
              value={organization}
            />
            <Form.Input
              label="Department / Unit (if applicable)"
              id="department"
              name="user[department]"
              width={8}
              onChange={this.handleChange}
              error={errors['department']}
              value={department}
            />
          </Form.Group>

          <Form.Field
            error={errors['position'] ? true : false}
          >
            <label>Position</label>
            <select
              error={errors['position']}
              className="ui fluid dropdown"
              id="position"
              name="user[position]"
              onChange={this.handleChange}
              value={position}
            >
              <option value="">
                Please select position
              </option>
              {positionOptions.map((position) =>
                <option key={position.key} value={position.value}>
                  {position.text}
                </option>
              )}
            </select>
          </Form.Field>
          { position === 'Other'
              ?
              (
                <Form.Field>
                  <Form.Input
                    error={errors['other_position']}
                    id="other_position"
                    name="user[other_position]"
                    onChange={this.handleChange}
                    value={other_position}
                    placeholder="Please specify"
                  />
                </Form.Field>
              )
              : null
            }

          <Divider />

          <h3>Mailing address</h3>
          <Form.Input
            label="Address"
            id="address"
            name="user[address]"
            onChange={this.handleChange}
            error={errors['address']}
            value={address}
          />

          <Form.Field>
            <label>Address 2 (Optional)</label>
            <Input
              id="address2"
              name="user[address2]"
              onChange={this.handleChange}
              value={address2}
            />
          </Form.Field>


          <Form.Group>
            <Form.Input
              label="City"
              id="city"
              name="user[city]"
              width={8}
              onChange={this.handleChange}
              error={errors['city']}
              value={city}
            />
            <Form.Input
              label="State"
              id="state"
              name="user[state]"
              width={4}
              onChange={this.handleChange}
              error={errors['state']}
              value={state}
            />
            <Form.Input
              label="Zip"
              id="zip"
              name="user[zip]"
              width={4}
              onChange={this.handleChange}
              error={errors['zip']}
              value={zip}
            />
          </Form.Group>

          <Divider />

          <div className={errors['org_type'] ? "field error" : "field"}>
            <label htmlFor="user_org_type">Organization Type</label>
            <select
              name="user[org_type]"
              id="org_type"
              onChange={this.handleChange}
              className="ui fluid dropdown"
              value={org_type}
            >
              <option value={''}>Please select the type of organization you are affiliated with</option>

              <optgroup label="Government">
                <option value="federal">Federal</option>
                <option value="state">State</option>
                <option value="local">Local</option>
              </optgroup>
              <optgroup label="Educational or Research Institution">
                <option value="college">College/University</option>
                <option value="school">Primary/Secondary School</option>
                <option value="other_research">Other Research Institution</option>
              </optgroup>
              <optgroup label="Other">
                <option value="philanthropic">Philanthropic Organization</option>
                <option value="consulting">Consulting Firm</option>
                <option value="other">Other</option>
              </optgroup>
            </select>
          </div>

          <div className={errors['intended_use'] ? "field error" : "field"}>
            <label htmlFor="user_intended_use">Intended Use</label>
            <select
              name="user[intended_use]"
              id="intended_use"
              onChange={this.handleChange}
              className="ui fluid dropdown"
              value={intended_use}
            >
              <option value={''}>Please tell us how you intend to use AEP data</option>
              <option value={'advocacy'}>Advocacy</option>
              <option value={'general_information'}>General Information</option>
              <option value={'policy'}>Policy Action</option>
              <option value={'proposal'}>Proposal</option>
              <option value={'research'}>Conduct Research</option>
              <option value={'media'}>Media Release</option>
              <option value={'report'}>Prepare Report</option>
            </select>
          </div>

          <Divider />

          <Form.Field error={errors['receive_announcments']}>
            <label htmlFor="user_receive_announcments">Would you like to receive announcements about new AEP data, publications, etc.?</label>
          </Form.Field>

          <Form.Field error={errors['receive_announcments']}>
            <Radio
              label='Yes'
              id="receive_yes"
              name='user[receive_announcments]'
              checked={receive_announcments == "1"}
              value="1"
              onChange={this.handleChange}
            />
          </Form.Field>

          <Form.Field error={errors['receive_announcments']}>
            <Radio
              label='No'
              id="receive_no"
              name='user[receive_announcments]'
              checked={receive_announcments == "0"}
              value="0"
              onChange={this.handleChange}
            />
          </Form.Field>

          <input
            type="submit"
            className='fluid ui button'
            value="Submit"
            onClick={this.handleSubmit}
            disabled={this.state.isSubmitting}
          />
        </div>

        { showTerms ?
          <ModalContainer
            type="TermsModal"
            data={true}
            onClose={this.onCloseTermsModal}
          /> :
          null }
      </div>
    )
  }
}

export default Registration
