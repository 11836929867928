import React from 'react'
import moment from 'moment'
import { Table } from 'semantic-ui-react'
import { applicationStatus } from '../../constants'

const RestrictedApplicationTable = ({ data, onClick }) => {
  const handleClick = (record) => {
    onClick(record)
  }
  console.log('data', data)
  return (
    <Table className="restricted-application-table" selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Applicant</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Date submitted</Table.HeaderCell>
          <Table.HeaderCell>Last updated</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        { data !== undefined && data.length > 0
        ? (data.map((record) => {
          const appData = record
          const user = record.user[0]
          return (
            <Table.Row
              key={`application-${appData._id.$oid}`}
              onClick={() => handleClick(appData._id.$oid)}
            >
              <Table.Cell>
                {`${user.first_name} ${user.last_name}`}
              </Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
              <Table.Cell>
                {moment(appData.created_at).format('MM/DD/YYYY')}
              </Table.Cell>
              <Table.Cell>
                {moment(appData.updated_at).format('MM/DD/YYYY')}
              </Table.Cell>
              <Table.Cell>
                {applicationStatus[appData.status]}
              </Table.Cell>
            </Table.Row>
          )}))
        : <Table.Row><Table.Cell colSpan={5}>No applications.</Table.Cell></Table.Row>
        }
      </Table.Body>
    </Table>
  )
}

export default RestrictedApplicationTable