import React, { Component } from 'react';
import axios from 'axios'
import {
  Modal,
} from 'semantic-ui-react'

import SurveyForm from './survey_form'


class CreateSurveyModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
    }

    this.createSurvey = this.createSurvey.bind(this)
  }

  createSurvey(formData) {
    const csrfToken = document.getElementsByName('csrf-token')[0].content
    this.setState({
      isLoading: true
    }, () => {
      axios.post('/api/v1/surveys', formData
      , {
        headers: {
          'X-CSRF-TOKEN': csrfToken,
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        console.log('response', response)
        this.props.onClose(true)
      }).catch((err) => {
        this.setState({
          isLoading: false,
        })
      })
    })

  }

  render() {

    return (
      <Modal open={true} onClose={this.props.onClose} centered={false}>
        <Modal.Header>Create a Survey</Modal.Header>
        <Modal.Content>
          <SurveyForm
            isLoading={this.state.isLoading}
            onSubmit={this.createSurvey}
          />
        </Modal.Content>
      </Modal>
    );
  }

}

export default CreateSurveyModal;
