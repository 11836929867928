import React, { Component } from 'react';
import { Card, Container, Grid, Header, Icon, Loader, Segment, Statistic } from 'semantic-ui-react'
import axios from 'axios'

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      surveyCount: null,
      userCount: null,
    }
  }

  componentDidMount() {
    axios.get('/api/v1/admins/counts')
      .then((response) => {
        this.setState({
          isLoading: false,
          surveyCount: response.data.survey_count,
          userCount: response.data.user_count
        })
      })
  }

  render() {
    const { isLoading, surveyCount, userCount } = this.state
    return (
      <div id="aep-dashboard" className='aep-admin__container'>
        <Container>
          <Header as='h1'>
            Dashboard
          </Header>

          <Card.Group
            itemsPerRow={3}
          >
            <Card
              href='/users'
            >
              <Card.Content textAlign='center'>
                <Icon name='users' size='huge'/>
                <Card.Description>
                  <Statistic>
                    <Statistic.Value>
                      {isLoading ? <Loader active inline /> : userCount }
                    </Statistic.Value>
                    <Statistic.Label>Registered Users</Statistic.Label>
                  </Statistic>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card
              href='/surveys'
            >
              <Card.Content textAlign='center'>
                <Icon name='file alternate outline' size='huge' />
                <Card.Description>
                  <Statistic>
                    <Statistic.Value>
                      {isLoading ? <Loader active inline /> : surveyCount }
                    </Statistic.Value>
                    <Statistic.Label>Surveys</Statistic.Label>
                  </Statistic>
                </Card.Description>
              </Card.Content>
            </Card>
          </Card.Group>
        </Container>
      </div>
    );
  }

}

export default Dashboard;
