import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Loader,
  Message,
} from 'semantic-ui-react'
import Navigation from 'components/navigation'
import renderHTML from 'html-react-parser';
import ScholarshipApplicationModal from './scholarships/application_modal'

const Scholarship = ({ currentPage, profileComplete }) => {
  const [scholarship, setScholarship] = useState([])
  const [loading, toggleLoading] = useState(true)
  const [apply, toggleApply] = useState(false)
  const [existingApplications, setExistingApplications] = useState([])

  const getScholarshipApplications = async () => {
    try {
      const response = await axios.get('/api/v1/my-scholarship-applications')
      setExistingApplications(response.data.data)
    } catch (err) {
      console.error('err', err)
      console.error('err', err.stack)
    }
  }

  const fetchActiveScholarship = async () => {
    try {
      const response = await axios.get('/api/v1/scholarships')
      setScholarship(response.data.data)
    } catch (err) {
      console.error('err', err)
      console.error('err', err.stack)
    } finally {
      toggleLoading(false)
    }
  }

  const openApplication = () => toggleApply(true)
  const closeApplication = () => {
    toggleApply(false)
    getScholarshipApplications()
  }

  useEffect(() => {
    fetchActiveScholarship()
    getScholarshipApplications()
  }, [])

  if (loading) {
    return (<Loader active />)
  }

  const activeScholarship = scholarship[0]
  const hasExistingScholarship = existingApplications.find((obj) => {
    return obj.scholarship_id.$oid === activeScholarship._id.$oid
  })

  console.log('hasExistingScholarship', hasExistingScholarship)
  return (
    <Container className="user-page">
      <Grid>
        <Grid.Column width={4}>
          <Navigation currentPage={currentPage} />
        </Grid.Column>

        <Grid.Column width={12}>
          <div className="page--header">
            <Header as='h1'>AEP Scholarship Program</Header>
          </div>
          <div className="page--content">
            {(activeScholarship === undefined || scholarship.length > 1) && (
              <Message info>
                <Icon name="warning" />
                There isn't a scholarship available yet, please check back later!
              </Message>
            )}
            {!loading && activeScholarship && (
              <div className="aep-scholarship">
                <Header textAlign="center">
                  {activeScholarship.name}
                </Header>
                {hasExistingScholarship
                  && hasExistingScholarship.status === 3
                  && hasExistingScholarship.submitted && (
                    <Message info>
                      <Message.Header style={{fontSize: '1em', paddingBottom: '1em'}}>
                        <Icon name="info circle" />
                        &nbsp;
                        Your AEP Scholarship application requires additional information.
                      </Message.Header>
                      <Message.Content>
                        To make edits, please click the button at the bottom of the page. You should have received guidance via the email address associated with your AEP Portal account. If you have questions, please contact Mallika Bhandarkar at <a href="mailto:mbhanda@rand.org">mbhanda@rand.org</a>.
                      </Message.Content>
                    </Message>
                )}
                {hasExistingScholarship
                  && hasExistingScholarship.status !== 3
                  && hasExistingScholarship.submitted && (
                  <Message positive>
                    <Icon name="info circle" />
                    Thank you for your interest in the {activeScholarship.name}. We have received your application and are in the process of reviewing it. You will receive written notice of your application status.
                  </Message>
                )}
                {hasExistingScholarship
                  && !hasExistingScholarship.submitted && (
                  <Message info>
                    <Icon name="info circle" />
                    Thank you for your interest in the {activeScholarship.name}. Don't forget, you have an application for this scholarship in progress!
                  </Message>
                )}
                <div className="aep-scholarship--description">
                  {renderHTML(activeScholarship.description)}
                </div>

                <div
                  className="aep-scholarship-apply"
                  style={{padding: '1rem 0'}}
                >
                  {!profileComplete && (
                    <Message warning>
                      <Icon name="warning" />
                      &nbsp;
                      In order to apply for an AEP Scholarship, you must first <a href="/profile">complete your profile.</a>
                    </Message>
                  )}
                  {!activeScholarship.accepting_applications && (
                    <Message info>
                      We are not yet accepting applications for this scholarship. Please contact us or check back for more information.
                    </Message>
                  )}
                  {activeScholarship.accepting_applications && (
                    <div
                      className="aep-scholarships-op"
                      style={{display: 'flex', justifyContent:"space-around", padding: '1rem'}}
                    >
                      {hasExistingScholarship
                        && hasExistingScholarship.status === 3 && (
                        <Button
                          size="large"
                          color="green"
                          onClick={openApplication}
                        >
                          Edit Application
                        </Button>
                      )}
                      {!hasExistingScholarship && (
                        <Button
                          size="large"
                          color="green"
                          onClick={openApplication}
                        >
                          Apply for AEP Scholarship
                        </Button>
                      )}
                      {hasExistingScholarship
                        && hasExistingScholarship.status !== 3 && (
                          <Button
                          size="large"
                          color="green"
                          onClick={openApplication}
                          disabled={!profileComplete
                                    || (hasExistingScholarship
                                      && hasExistingScholarship.submitted)}
                        >
                          {!hasExistingScholarship.submitted
                            ? 'Complete AEP Scholarship Application'
                            : 'Apply for AEP Scholarship'
                          }
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Grid.Column>
      </Grid>
      {apply && (
        <ScholarshipApplicationModal
          open={apply}
          onClose={closeApplication}
          scholarshipId={activeScholarship._id.$oid}
          data={hasExistingScholarship}
        />
      )}
    </Container>
  )
}

export default Scholarship