import React from 'react'
import { Card, Container, Grid, Header, List } from 'semantic-ui-react'
import get from 'lodash/get'
import Navigation from 'components/navigation'
import renderHTML from 'html-react-parser';

const RestrictedFaq = ({ faqs, currentPage }) => {
    return (
        <Container className="user-page">
            <Grid>
                <Grid.Column width={4}>
                    <Navigation currentPage={currentPage} />
                </Grid.Column>

                <Grid.Column width={12}>
                    <Header as="h1">AEP Data Portal: Restricted Data FAQs</Header>

                    <List id="faq-list">
                        {faqs.map((faq) => (
                            <List.Item key={`restricted-faq-${get(faq, '_id.$oid')}`}>
                                <List.Content>
                                    <List.Header>
                                        {faq.title}
                                    </List.Header>
                                    <List.Description>
                                        {renderHTML(faq.body)}
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                        ))}
                    </List>
                    <p>
                        For more information, please email <a href="mailto:aepdata@rand.org">aepdata@rand.org</a>.
                    </p>
                </Grid.Column>
            </Grid>
        </Container>
    )
}

export default RestrictedFaq