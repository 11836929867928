import React from 'react'
import { Button, Card, Divider, Grid, Header, Label, List, Modal, Table } from 'semantic-ui-react'

import { fileTypes } from '../constants'

const SurveyModal = (props) => (
  <Modal open={true} onClose={props.onClose} size='small'>
    <Modal.Header>{props.data.name}</Modal.Header>
    <Modal.Content>
      <p>{props.data.description}</p>
      <Modal.Description>
        <Grid columns={2}>
          <Grid.Column>
            <div id="topics">
              <Header as='h3'>Topics</Header>
              {props.data.topics.map((topic, idx) => {
                return <Label key={`${topic}-label-${idx}`}>{topic}</Label>
              })}
            </div>
          </Grid.Column>
          <Grid.Column>
            <div id="keywords">
              <Header as='h3'>Keywords</Header>
              {props.data.keywords.map((keyword, idx) => {
                return <Label key={`${keyword}-label-${idx}`}>{keyword}</Label>
              })}
            </div>
          </Grid.Column>
        </Grid>

        { props.data.survey_files !== undefined && props.data.survey_files.length > 0 ?
          <div id="files">
            <Divider />

            <Header as='h3'>Files</Header>
            <List bulleted relaxed>
              {props.data.survey_files.map((file, idx) => {
                const typeFilter = fileTypes.filter((type) => {
                  return type.value == file.type
                })

                const fileType = typeFilter.length != 1 ? 'File' : typeFilter[0]                

                return (
                  <List.Item key={`${file}-${idx}`}>
                    <List.Content>
                      <List.Header
                        as='a'
                        href={`/api/v1/surveys/${props.data._id.$oid}/files/${file._id.$oid}`}
                        target="_blank"
                        onClick={() => props.handleEvent(file.name)}
                      >
                        {file.name}
                      </List.Header>
                      <List.Description>{fileType.text}</List.Description>
                    </List.Content>
                  </List.Item>
                )
              })}
            </List>
          </div> :
          '' }
      </Modal.Description>
    </Modal.Content>
  </Modal>
)

export default SurveyModal
