import React from 'react'
import { Button, Modal, Tab, } from 'semantic-ui-react'
import ScholarshipApplicationActivity from './activity'
import ScholarshipApplicationReview from './review'

function ScholarshipApplicationAdmin({
  data,
  onClose,
  onSubmit,
  open,
  scholarship,
}) {
  const panes = [
    { menuItem: 'Review', render: () => (
      <Tab.Pane>
        <ScholarshipApplicationReview
          data={data}
          scholarship={scholarship}
          onSubmit={onSubmit}
        />
      </Tab.Pane>
    )},
    { menuItem: 'Activity', render: () => (
      <Tab.Pane>
        <ScholarshipApplicationActivity
          applicationId={data._id.$oid}
        />
      </Tab.Pane>
    )},
  ]

  return (
    <Modal
      open={open}
      onClose={onClose}
      centered={false}
    >
      <Modal.Header>Scholarship Application Review</Modal.Header>
      <Modal.Content>
        <Tab panes={panes} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ScholarshipApplicationAdmin