import axios from 'axios'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Button,
  Checkbox,
  Form,
  Loader,
  Modal,
  Dimmer
} from 'semantic-ui-react'
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/code';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/help';
import 'tinymce/plugins/wordcount';
import { Editor } from '@tinymce/tinymce-react'

const csrfToken = document.getElementsByName('csrf-token')[0].content

const helpStyle = {
  color: '#666',
  fontSize: '0.9rem',
  fontWeight: 'normal',
}

const lastUpdateStyle = {
  ...helpStyle,
  float: 'right',
}

const editorInit = {
  height: 500,
  menubar: false,
  plugins: [
    'advlist lists link charmap preview anchor code',
    'searchreplace visualblocks',
    'insertdatetime table paste help wordcount'
  ],
  toolbar:
    'undo redo | formatselect | bold italic backcolor | \
    alignleft aligncenter alignright alignjustify | \
    bullist numlist outdent indent | removeformat | help'
}

function PageEditor({open, onClose, slug}) {
  const [loading, toggleLoading] = useState(true)
  const [name, updateName] = useState('')
  const [body, updateBody] = useState('')
  const [pageRecord, setPageRecord] = useState(null)

  const handleNameChange = (e, { value }) => updateName(value)
  const handleEditorChange = (content, editor) => {
    updateBody(content)
  }

  const handleSubmit = async () => {
    const formData = {
      name,
      body,
      active: true,
    }
    try {
      const response = await axios.put(`/api/v1/pages/${slug}`,
        { page: formData }, {
        headers: {
          'X-CSRF-TOKEN': csrfToken,
        }
      })
      console.log('response', response)
      onClose()
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    }
  }

  useEffect(() => {
    const fetchPage = async () => {
      try {
        const response = await axios.get(`/api/v1/pages/${slug}`)
        console.log('response', response)
        updateName(response.data.name)
        updateBody(response.data.body)
        setPageRecord(response.data)
      } catch (err) {
        console.error('err', err)
        console.error('err.stack', err.stack)
      } finally {
        toggleLoading(false)
      }
    }
    fetchPage()
  }, [slug])

  if (loading) {
    return (
      <Dimmer>
        <Loader active />
      </Dimmer>
    )
  }

  return (
    <Modal
      centered={false}
      open={open}
      onClose={onClose}
    >
      <Modal.Header style={{display: 'flex', justifyContent: 'space-between'}}>
        <span>
          Edit {pageRecord.name}
        </span>
        <span style={{color: '#666', fontSize: '1rem', fontWeight: 100}}>
          Last updated: {moment(pageRecord.updated_at).format('MM/DD/YYYY')}
        </span>
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Name</label>
            <Form.Input
              type="text"
              onChange={handleNameChange}
              value={name}
            />
          </Form.Field>
          <Form.Field>
            <label>Description</label>
            <Editor
              init={editorInit}
              onEditorChange={handleEditorChange}
              value={body}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="green"
          disabled={loading}
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default PageEditor