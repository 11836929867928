import React, { Component } from 'react';
import axios from 'axios'
import PageEditor from 'components/page_editor'
import { Button, Container, Form, Header, Icon, Input, Loader, Pagination, Segment } from 'semantic-ui-react'

import ModalContainer from '../modals/modal_container'
import SurveyTable from './survey_table'
import SurveyGrid from './survey_grid'
import SurveyList from './survey_list'
import { randomKeyString } from '../utils'

let searchTimeout = null

class Surveys extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activePage: 1,
      isLoading: true,
      query: '',
      surveys: [],
      surveyCount: 0,
      key: null,
      creatingSurvey: false,
      activeSurvey: null,
      removingSurvey: null,
      editingSurvey: null,
      surveyMetrics: false,
      viewMode: 'Admin',
      displayMode: 'grid',
      pageModal: false,
    }

    this.showCreateSurveyModal = this.showCreateSurveyModal.bind(this)
    this.onCloseCreateSurveyModal = this.onCloseCreateSurveyModal.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.onCloseSurveyModal = this.onCloseSurveyModal.bind(this)
    this.showSurveyModal = this.showSurveyModal.bind(this)
    this.showMetricsModal = this.showMetricsModal.bind(this)
    this.onCloseMetricsModal = this.onCloseMetricsModal.bind(this)

    this.showDeleteSurveyModal = this.showDeleteSurveyModal.bind(this)
    this.onCloseDeleteSurveyModal = this.onCloseDeleteSurveyModal.bind(this)

    this.showEditSurveyModal = this.showEditSurveyModal.bind(this)
    this.onCloseEditSurveyModal = this.onCloseEditSurveyModal.bind(this)
  }

  getSurveys(activePage) {
    const setPage = activePage === undefined ? 1 : activePage
    const surveyUrl = activePage === undefined ? '/api/v1/surveys.json' : `/api/v1/surveys.json?page=${activePage}`

    const newKey = randomKeyString(7)

    axios.get(surveyUrl)
      .then((response) => {
        this.setState({
          activePage: setPage,
          isLoading: false,
          surveys: response.data.surveys,
          surveyCount: response.data.survey_count,
          key: newKey
        })
      })
  }

  searchSurveys = (activePage) => {
    const { query } = this.state
    const setPage = activePage === undefined ? 1 : activePage

    if (query === '') {
      this.getSurveys()
      return
    }
    const newKey = randomKeyString(7)

    axios.get(`/api/v1/surveys/search?query=${query}`)
      .then((response) => {
        this.setState({
          activePage: setPage,
          isLoading: false,
          surveys: response.data.surveys,
          surveyCount: response.data.survey_count,
          key: `surveys-search-page-${newKey}`
        })
      })
  }

  componentDidMount() {
    this.getSurveys()
  }

  handlePageChange = (e, { activePage }) => {
    this.setState({
      isLoading: true
    }, () => {
      this.getSurveys(activePage)
    })
  }

  openPageModal = () => {
    this.setState({
      pageModal: true,
    })
  }

  closePageModal = () => {
    this.setState({
      pageModal: false
    })
  }

  showSearch() {
    const { activePage, isLoading, query, surveys, surveyCount } = this.state

    return (
      <Input
        type="text"
        onChange={this.handleSearchChange}
        onKeyPress={this.handleSearchKeyPress}
        value={query}
        placeholder='Search for a survey by name, topics or keywords'
        fluid
        action={{ icon: 'search', onClick: this.searchSurveys }}
      />
    )
  }

  handleSearchChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  handleSearchKeyPress = (e) => {
    if (e.charCode == 13) {
      this.searchSurveys()
    }
  }

  clearSearch(e) {
    this.setState({
      query: '',
    }, () => this.getSurveys())
  }

  showPagination() {
    const { activePage, isLoading, surveys, surveyCount } = this.state
    if (surveys.length > 0) {
      return (
        <div className='aep-admin__pagination'>
          <Pagination
            activePage={activePage}
            secondary
            totalPages={Math.ceil(surveyCount / 12)}
            onPageChange={this.handlePageChange}
          />
      </div>
      )
    }
  }

  showCreateSurveyModal() {
    this.setState({
      creatingSurvey: true
    })
  }

  onCloseCreateSurveyModal(surveyCreated) {
    this.setState({
      creatingSurvey: false
    }, () => {
      if (surveyCreated) {
        this.getSurveys()
      }
    })
  }

  showSurveyModal(surveyId) {
    axios.get(`/api/v1/surveys/${surveyId}`)
      .then((response) => {
        this.setState({
          activeSurvey: response.data.survey
        })
      })
  }

  onCloseSurveyModal() {
    this.setState({
      activeSurvey: null
    })
  }

  showEditSurveyModal(surveyId) {
    axios.get(`/api/v1/surveys/${surveyId}`)
      .then((response) => {
        this.setState({
          editingSurvey: response.data.survey
        })
      })
  }

  onCloseEditSurveyModal(surveyUpdated) {
    this.setState({
      editingSurvey: null
    }, () => {
      if (surveyUpdated) {
        this.getSurveys()
      }
    })
  }

  showDeleteSurveyModal(surveyId) {
    axios.get(`/api/v1/surveys/${surveyId}`)
      .then((response) => {
        this.setState({
          removingSurvey: response.data.survey
        })
      })
  }

  onCloseDeleteSurveyModal(surveyRemoved) {
    this.setState({
      removingSurvey: null
    }, () => {
      if (surveyRemoved) {
        this.getSurveys()
      }
    })
  }

  showMetricsModal(surveyId) {
    axios.get(`/api/v1/surveys/${surveyId}/metrics`)
      .then((response) => {
        this.setState({
          surveyMetrics: response.data
        })
      })
  }

  onCloseMetricsModal() {
    this.setState({
      surveyMetrics: null,
    })
  }

  toggleViewMode = () => {
    const viewMode = this.state.viewMode === 'Admin' ? 'User' : 'Admin'
    this.setState({
      viewMode,
    })
  }

  toggleDisplayMode = (mode) => {
    const { displayMode } = this.state
    if (mode !== displayMode) {
      this.setState({
        displayMode: mode
      })
    }
  }

  render() {
    const { activeSurvey, creatingSurvey, displayMode, editingSurvey, surveyMetrics, isLoading, key, removingSurvey, surveys, viewMode } = this.state
    return (
      <div id="surveys" className='aep-admin__container'>
        <Container>
          <div className='aep-admin__header'>
            { viewMode == 'User' ?
              (<Button.Group>
                <Button
                  active={displayMode == 'grid' ? true : undefined}
                  icon='grid layout'
                  onClick={this.toggleDisplayMode.bind(null, 'grid')}
                />
                <Button
                  active={displayMode == 'list' ? true : undefined}
                  icon='list layout'
                  onClick={this.toggleDisplayMode.bind(null, 'list')}
                />
              </Button.Group>) : null}
            <Button
              floated="right"
              icon
              labelPosition='left'
              onClick={this.openPageModal}
              style={{ 'marginBottom': '1em' }}
            >
              <Icon name='edit' />
              Edit Page
            </Button>
            <Button
              floated="right"
              icon
              labelPosition='left'
              onClick={this.toggleViewMode}
              style={{ 'marginBottom': '1em' }}
            >
              <Icon name='exchange' />
              {`${viewMode} View`}
            </Button>
            <Button
              floated="right"
              icon
              labelPosition='left'
              onClick={this.showCreateSurveyModal}
              style={{ 'marginBottom': '1em' }}
            >
              <Icon name='book' />
              Add New Survey
            </Button>
            <Header as='h1'>Surveys</Header>
          </div>

          <div className='aep-admin__search'>
            <Segment>
              {this.showSearch()}
            </Segment>
          </div>


          { viewMode == 'Admin' ?
            (<Segment textAlign='center'>
              { isLoading ?
                <Loader inline /> :
                <SurveyTable
                  data={surveys}
                  key={key}
                  showSurveyModal={this.showSurveyModal}
                  showDeleteSurveyModal={this.showDeleteSurveyModal}
                  showEditSurveyModal={this.showEditSurveyModal}
                  showMetricsModal={this.showMetricsModal}
                />
              }

              {this.showPagination()}
            </Segment>):
            <Segment>
              { isLoading ?
                <Loader inline /> :(
                    displayMode == 'grid' ?
                      <SurveyGrid
                        surveys={surveys}
                        showSurveyModal={this.showSurveyModal}
                      /> :
                      <SurveyList
                        surveys={surveys}
                        showSurveyModal={this.showSurveyModal}
                      />
                  )
              }

              {this.showPagination()}
            </Segment>
          }

        </Container>

        { creatingSurvey ?
          <ModalContainer
            type="CreateSurveyModal"
            data={true}
            onClose={this.onCloseCreateSurveyModal}
          /> :
          null }

        { activeSurvey ?
          <ModalContainer
            type="SurveyModal"
            data={activeSurvey}
            onClose={this.onCloseSurveyModal}
          /> :
          null }

        { removingSurvey ?
          <ModalContainer
            type="DeleteSurveyModal"
            data={removingSurvey}
            onClose={this.onCloseDeleteSurveyModal}
          /> :
          null
        }

        { surveyMetrics ?
          <ModalContainer
            type="MetricsSurveyModal"
            data={surveyMetrics}
            onClose={this.onCloseMetricsModal}
          /> :
          null
        }

        { editingSurvey ?
          <ModalContainer
            type="EditSurveyModal"
            data={editingSurvey}
            onClose={this.onCloseEditSurveyModal}
            key={`edit-survey-${key}`}
          /> :
          null
        }
        {this.state.pageModal && (
          <PageEditor
            open={this.state.pageModal}
            onClose={this.closePageModal}
            slug="deidentified-data"
          />
        )}
      </div>
    );
  }

}

export default Surveys;
