import React, { useEffect, useState } from 'react';
import {Button, Checkbox, Form, FormField, Input, Loader, Modal} from 'semantic-ui-react';
import get from 'lodash/get'
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/code';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/help';
import 'tinymce/plugins/wordcount';
import { Editor } from '@tinymce/tinymce-react'

const editorInit = {
    height: 250,
    menubar: false,
    plugins: [
        'advlist lists link charmap preview anchor code',
        'searchreplace visualblocks',
        'insertdatetime table paste help wordcount'
    ],
    toolbar:
        'undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | help'
}

function FAQModal({ loading, data, maxOrder, inputOrder, onSave, onClose }) {
    const [faqData, setFaqData] = useState({
        title: '',
        body: '',
        order: '',
        active: false,
        restricted: true,
    })

    useEffect(() => {
        if (data) {
            setFaqData(data)
        } else {
            setFaqData((prev) => {
                return {
                    ...prev,
                    order: inputOrder
                }
            })
        }
    }, [data, inputOrder])

    const { title, body, order, active, restricted } = faqData

    const handleFieldUpdate = (name, value) => {
        setFaqData({
            ...faqData,
            [name]: value,
        })
    }

    const handleEditorUpdate = (content, editor) => {
        setFaqData({
            ...faqData,
            body: content,
        })
    }

    const toggleCheckbox = (name) => {
        setFaqData({
            ...faqData,
            [name]: !faqData[name],
        })
    }

    const handleSave = () => {
        if (data) {
            onSave(get(data, '_id.$oid'), faqData)
        } else {
            onSave(faqData)
        }
    }

    const invalidForm = title === '' || body === '' || order === ''

    return (
        <Modal
            open={true}
            closeOnDimmerClick={false}
            onClose={onClose}
            centered={false}
        >
            <Modal.Header>
                {data ? `Edit ${get(data, 'title')}` : 'Create FAQ'}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Title</label>
                        <Input
                            type="text"
                            value={title}
                            onChange={(e, {value}) => handleFieldUpdate('title', value)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Body</label>
                        <Editor
                            init={editorInit}
                            value={body}
                            onEditorChange={handleEditorUpdate}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Order</label>
                        <Input
                            type="number"
                            min="1"
                            max={maxOrder}
                            value={order}
                            onChange={(e, {value}) => handleFieldUpdate('order', value)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox label='Active' checked={active} onChange={() => toggleCheckbox('active')} />
                        <p className="help-block" style={{ fontSize: '0.85rem', color: '#666' }}>
                            Indicates whether or not users can see this FAQ.
                        </p>
                    </Form.Field>
                    <Form.Field>
                        <Checkbox label='Restricted' readOnly checked={restricted} onChange={() => toggleCheckbox('restricted')}/>
                        <p className="help-block" style={{ fontSize: '0.85rem', color: '#666' }}>
                            Indicates whether or not this FAQ applies to restricted data.
                        </p>
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button basic onClick={onClose}>Cancel</Button>
                <Button color="green" disabled={invalidForm || loading} onClick={handleSave}>
                    {loading ? <Loader inline size="mini" active /> : 'Save'}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default FAQModal