import React from 'react'
import map from 'lodash/map'
import { Button, Form, Icon, Loader, Message, TextArea } from 'semantic-ui-react'

const InitialApplication = ({
  data,
  onSelect,
  handleChange,
  selectedCv,
  uploadedCv,
  onUpload,
  onReset,
  cvKey,
  cvError,
  isUploading,
  isLoading,
  wordCounts,
  wordLimits,
  addColleague,
  updateColleague,
  removeColleague,
}) => {
  return (
    <div className="initial-application">
      <p>
        Thank you for your interest in AEP Restricted Data for use in your research. If you have not done so already, please complete your profile on the AEP Portal in addition to the below form as part of your application.
      </p>
      <p>
        <b>Items marked with a <span style={{color: 'red'}}>*</span> are required.</b>
      </p>
      <Form>
        <Form.Field
          required
          error={wordCounts.summary > wordLimits.summary}
        >
          <label>Summary of research: Please be specific and include the data you plan to use, the hypotheses you plan to examine and key variables for your analysis.</label>
          <TextArea
            onChange={(e, { value}) => handleChange('summary', value)}
            value={data.summary}
          />
          <div className="word-limit">
            {wordCounts['summary']} / {wordLimits['summary']} words
          </div>
        </Form.Field>

        <Form.Field required={!uploadedCv}>
          <label>CV</label>
          { isLoading && (
            <Loader active />
          )}
          {!isLoading && uploadedCv && (
            <div className="uploaded-cv">
              <Icon name='file' />
              <span className="cv filename">
                {uploadedCv.filename}
              </span>
              <span className="cv upload-date">
                {uploadedCv.upload_date}
              </span>
            </div>
          )}
          {cvError && (
            <Message color="red">
              <p>{cvError}</p>
            </Message>
          )}
          <input
            type="file"
            onChange={(e) => onSelect(e)}
            key={cvKey}
          />
          {uploadedCv && !selectedCv && (
            <div className="uploaded-cv">
              <span>A CV is already attached to your profile. If you want to update an existing CV, please upload it here.</span>
            </div>
          )}
          {selectedCv && (
            <div className="upload-op" style={{margin: '1rem 0'}}>
              <Button onClick={onUpload} disabled={isUploading || cvError !== null}>
                Upload CV
              </Button>
              <Button onClick={onReset}>
                Reset
              </Button>
            </div>
          )}
        </Form.Field>
        <Form.Field>
          <label>
            If up to four additional research colleagues will have access to the data, please list them here.
            <br />
            They must be at same institution.
          </label>
          {map(data.colleagueOrder, (key) => (
            <Form.Group key={`colleague-input-${key}`}>
              <Form.Field style={{flex: 10}}>
                <Form.Input
                  type="text"
                  value={data.colleagues[key]}
                  onChange={(e, {value}) => updateColleague(value, key)}
                />
              </Form.Field>
              <Form.Field>
                <Button
                  color="red"
                  icon
                  onClick={(e) => removeColleague(key)}
                >
                  <Icon name="cancel" />
                </Button>
              </Form.Field>
            </Form.Group>
          ))}
          <Button
            disabled={data.colleagueOrder.length >= 4}
            icon
            onClick={addColleague}
            labelPosition="left"
          >
            <Icon name="user add" />
            Add colleague
          </Button>
        </Form.Field>
      </Form>
    </div>
  )
}

export default InitialApplication