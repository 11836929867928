import axios from 'axios'
import React, { useCallback, useState, useEffect } from 'react'
import {
  Button,
  Modal,
} from 'semantic-ui-react'
import ApplicationForm from './application_form'
import Addon from './addon'
import Review from './review'
import get from 'lodash/get'
import { randomKeyString } from '../../../utils'
import times from 'lodash/times'
import { cvMimeTypes } from '../../../constants'
import { wordCounts, wordLimits } from './constants'

const applicationTemplate = {
  ethnicity: [],
  ethnicity_other: '',
  find_out: '',
  fit: '',
  analyzed_micro_level: '',
  preferred_stat_platform: '',
  summary: '',
  data_interest: '',
  access_to_restricted_data: '',
}

const errorTemplate = {
  find_out: false,
  fit: false,
  preferred_stat_platform: false,
  summary: false,
  justification: false,
  security_statement: false,
}

const restrictedAddonTemplate = {
  justification: '',
  security_statement: '',
}

const applicationSteps = {
  APPLY: 0,
  ADDON: 1,
  SUBMIT: 2,
}

const csrfToken = document.getElementsByName('csrf-token')[0].content

function ScholarshipApplicationModal({ data, open, onClose, scholarshipId }) {
  const [applicationData, updateApplicationData] = useState(applicationTemplate)
  const [restrictedData, updateRestrictedData] = useState()
  const [currentStep, updateCurrentStep] = useState(applicationSteps.APPLY)
  const [cvFile, setCvFile] = useState(null)
  const [uploadedCv, setUploadedCv] = useState(null)
  const [cvKey, setCvKey] = useState('')
  const [isUploading, toggleUploading] = useState(false)
  const [cvError, setCvError] = useState(null)
  const [loadingCv, toggleLoadingCv] = useState(true)
  const [applicationErrors, setApplicationErrors] = useState(errorTemplate)
  const [applicationCounts, setApplicationCounts] = useState(wordCounts)
  console.log('data', data)
  const getUploadedCv = useCallback(async () => {
    try {
      const response = await axios.get('/api/v1/cv')
      console.log('response', response)
      setUploadedCv(response.data)
    } catch (err) {
      console.error('err', err)
      console.error('err', err.stack)
    } finally {
      toggleLoadingCv(false)
    }
  }, [uploadedCv])

  const uploadCv = async () => {
    try {
      toggleUploading(true)
      const formData = new FormData()
      formData.append('cv[files][]', cvFile)
      const response = await axios.post('/api/v1/cv',
        formData, {
          headers: {
            'X-CSRF-TOKEN': csrfToken,
          }
      })
      setUploadedCv(response.data)
      setCvFile(null)
      setCvKey(randomKeyString())
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    } finally {
      toggleUploading(false)
    }
  }

  const selectCV = (e) => {
    console.log('e.target.name', e.target.name)
    console.log('e.target.files', e.target.files)
    if (e.target.files.length === 0) {
      setCvFile(null)
      setCvKey(randomKeyString())
      return
    }

    if (cvMimeTypes.indexOf(e.target.files[0].type) === -1) {
      setCvError('Only PDF or Word Documents will be accepted.')
    } else {
      setCvError(null)
    }
    setCvFile(e.target.files[0])
  }

  const resetCV = () => {
    setCvFile(null)
    setCvKey(randomKeyString())
    setCvError(null)
  }

  const getWordCount = (field, value) => {
    const wordRegex = /[^a-z\d\s]+/gi;
    const wordCounter = (value.trim()//remove whitespace
                      .replace(/[\W]+/g, ' ')
                      .replace(/([a-z]+)\b[.,]/g, '')//remove commas & fullstops
                      .replace(wordRegex, '')
                      .split(' ')// split words into array elements
                      .filter(function(x){// remove empty array elements
                        return x !== ''
                      }) || [])
    setApplicationCounts({
      ...applicationCounts,
      [field]: wordCounter.length,
    })
    return wordCounter.length
  }

  const handleChange = (field, value) => {
    const updatedData = { ...applicationData }
    updatedData[field] = value
    if (field in wordLimits) {
      const words = getWordCount(field, value)
      if (words > wordLimits[field]) {
        setApplicationErrors({
          ...applicationErrors,
          [field]: true,
        })
      } else {
        setApplicationErrors({
          ...applicationErrors,
          [field]: false,
        })
      }
    }
    updateApplicationData(updatedData)
  }

  const handleRestrictedChange = (field, value) => {
    const updatedData = { ...restrictedData }
    updatedData[field] = value
    if (field in wordLimits) {
      const words = getWordCount(field, value)
      if (words > wordLimits[field]) {
        setApplicationErrors({
          ...applicationErrors,
          [field]: true,
        })
      } else {
        setApplicationErrors({
          ...applicationErrors,
          [field]: false,
        })
      }
    }
    updateRestrictedData(updatedData)
  }

  const submitApplication = async (submitted) => {
    try {
      let formData
      if (applicationData.access_to_restricted_data) {
        formData = {
          ...applicationData,
          ...restrictedData,
          submitted,
          scholarship_id: scholarshipId,
      }
      } else {
        formData = {
          ...applicationData,
          submitted,
          scholarship_id: scholarshipId,
        }
      }
      let axiosConfig
      if (data && data._id.$oid) {
        axiosConfig = {
          method: 'PUT',
          url: `/api/v1/scholarship_applications/${data._id.$oid}`,
          data: formData,
          headers: {
            'X-CSRF-TOKEN': csrfToken,
          }
        }
      } else {
        axiosConfig = {
          method: 'POST',
          url: `/api/v1/scholarship_applications`,
          data: formData,
          headers: {
            'X-CSRF-TOKEN': csrfToken,
          }
        }
      }
      const response = await axios(axiosConfig)
      console.log('response', response)
      onClose()
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    }
  }

  const {
    find_out,
    fit,
    analyzed_micro_level,
    preferred_stat_platform,
    summary,
    data_interest,
    access_to_restricted_data,
  } = applicationData

  let noAdvance = false
  let stepErrors = false
  if (currentStep === applicationSteps.APPLY) {
    ['findout', 'fit', 'summary', 'data_interest'].forEach((k) => {
      if (applicationErrors[k]) {
        stepErrors = true
      }
    })
    noAdvance = find_out.trim() === ''
                || fit.trim() === ''
                || analyzed_micro_level === ''
                || preferred_stat_platform.trim() === ''
                || summary.trim() === ''
                || data_interest.trim() === ''
                || access_to_restricted_data === ''
                || uploadedCv === null
                || stepErrors
  } else if (currentStep === applicationSteps.ADDON) {
    ['justification', 'secruity_statement'].forEach((k) => {
      if (applicationErrors[k]) {
        stepErrors = true
      }
    })
    const { justification, security_statement } = restrictedData
    noAdvance = justification.trim() === ''
                || security_statement.trim() == ''
                || stepErrors
  }


  const advanceApplication = () => {
    if (currentStep === applicationSteps.APPLY
        && applicationData.access_to_restricted_data) {
      updateCurrentStep(applicationSteps.ADDON)
    } else {
      updateCurrentStep(applicationSteps.SUBMIT)
    }
  }

  const backApplication = () => {
    if (currentStep === applicationSteps.ADDON) {
      updateCurrentStep(applicationSteps.APPLY)
    } else if (currentStep === (applicationSteps.SUBMIT)
      && applicationData.access_to_restricted_data) {
        updateCurrentStep(applicationSteps.ADDON)
    } else {
      updateCurrentStep(applicationSteps.APPLY)
    }
  }

  useEffect(() => {
    const restrictedAddon= { ...restrictedAddonTemplate }
    updateRestrictedData(restrictedAddon)
    getUploadedCv()

    if (data) {
      const {
        ethnicity,
        ethnicity_other,
        find_out,
        fit,
        analyzed_micro_level,
        preferred_stat_platform,
        summary,
        data_interest,
        access_to_restricted_data,
      } = data
      updateApplicationData({
        ethnicity,
        ethnicity_other,
        find_out,
        fit,
        analyzed_micro_level,
        preferred_stat_platform,
        summary,
        data_interest,
        access_to_restricted_data,
      })

      const updatedDataCounts = {...wordCounts}
      Object.keys(wordCounts).forEach((key) => {
        let dataValue = get(data, key, '')
        if (dataValue === null) {
          dataValue = ''
        }
        const currentValue = getWordCount(key, dataValue)
        updatedDataCounts[key] = currentValue
      })
      setApplicationCounts(updatedDataCounts)
    }
  }, [])

  return (
    <Modal
      open={open}
      centered={false}
      onClose={onClose}
    >
      <Modal.Header>AEP Scholarship Application</Modal.Header>
      <Modal.Content>
        {currentStep === applicationSteps.APPLY && (
          <ApplicationForm
            applicationData={applicationData}
            handleChange={handleChange}
            onSelect={selectCV}
            onUpload={uploadCv}
            selectedCv={cvFile}
            uploadedCv={uploadedCv}
            onReset={resetCV}
            cvKey={cvKey}
            isUploading={isUploading}
            cvError={cvError}
            isLoading={loadingCv}
            errors={applicationErrors}
            wordLimits={wordLimits}
            wordCounts={applicationCounts}
          />
        )}
        {currentStep === applicationSteps.ADDON && (
          <Addon
            data={restrictedData}
            handleChange={handleRestrictedChange}
            errors={applicationErrors}
            wordLimits={wordLimits}
            wordCounts={applicationCounts}
          />
        )}
        {currentStep === applicationSteps.SUBMIT && (
          <Review
            data={applicationData}
            restrictedData={restrictedData}
            handleChange={handleChange}
          />
        )}
      </Modal.Content>
      <Modal.Actions className="application-actions">
        <div className="save-button">
          <Button
            color="green"
            disabled={noAdvance}
            onClick={() => submitApplication(false)}
          >
            Save
          </Button>
        </div>
        <div className="advance-actions">
          {currentStep !== applicationSteps.APPLY && (
            <Button onClick={backApplication}>
              Previous
            </Button>
          )}
          <Button
            onClick={currentStep === applicationSteps.SUBMIT
              ? () => submitApplication(true)
              : advanceApplication
            }
            disabled={noAdvance}
            color={currentStep === applicationSteps.SUBMIT ? "green" : "grey" }
          >
            {currentStep === applicationSteps.SUBMIT
              ? 'Submit'
              : 'Next'
            }
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default ScholarshipApplicationModal