import React from 'react'
import {
  Divider,
  Grid,
  Header,
  Label,
  List,
  Modal,
} from 'semantic-ui-react'

import { fileTypes } from '../../../constants'

const RestrictedViewModal = ({ record, onClose, handleDownload }) => (
  <Modal open={true} onClose={onClose} size='small' centered={false}>
    <Modal.Header>{record.name} (Restricted)</Modal.Header>
    <Modal.Content>
      <p>{record.description}</p>
      <Modal.Description>
        <Grid columns={2}>
          <Grid.Column>
            <div id="topics">
              <Header as='h3'>Topics</Header>
              {record.topics.map((topic, idx) => {
                return <Label key={`${topic}-label-${idx}`}>{topic}</Label>
              })}
            </div>
          </Grid.Column>
          <Grid.Column>
            <div id="keywords">
              <Header as='h3'>Keywords</Header>
              {record.keywords.map((keyword, idx) => {
                return <Label key={`${keyword}-label-${idx}`}>{keyword}</Label>
              })}
            </div>
          </Grid.Column>
        </Grid>

        { record.restricted_data_files !== undefined && record.restricted_data_files.length > 0 ?
          <div id="files">
            <Divider />

            <Header as='h3'>Files</Header>
            <List bulleted relaxed>
              {record.restricted_data_files.map((file, idx) => {
                const typeFilter = fileTypes.filter((type) => {
                  return type.value == file.type
                })

                const fileType = typeFilter.length != 1 ? 'File' : typeFilter[0]

                return (
                  <List.Item key={`${file}-${idx}`}>
                    <List.Content>
                      <List.Header
                        as='a'
                        href={`/api/v1/restricted_data/${record._id.$oid}/files/${file._id.$oid}`}
                        target="_blank"
                        onClick={() => handleDownload(file.name)}
                      >
                        {file.name}
                      </List.Header>
                      <List.Description>{fileType.text}</List.Description>
                    </List.Content>
                  </List.Item>
                )
              })}
            </List>
          </div> :
          '' }
      </Modal.Description>
    </Modal.Content>
  </Modal>
)

export default RestrictedViewModal
