import React from 'react'
import { Container, Grid, Header } from 'semantic-ui-react'
import renderHTML from 'html-react-parser';
import Navigation from 'components/navigation'
import SurveyView from 'components/surveys/survey_view'

const Deidentified = ({ currentPage, content, trackingId }) => {
  return (
    <Container className="user-page">
      <Grid>
        <Grid.Column width={4}>
          <Navigation currentPage={currentPage} />
        </Grid.Column>

        <Grid.Column width={12}>
          <div className="page--header">
            <Header as='h1'>Deidentified Survey Data</Header>
          </div>
          <div className="page--content">
            {content !== null && (
              renderHTML(content)
            )}
            {content === null && (
              <React.Fragment>
                <p>
                  Deidentified survey data files from several AEP studies are provided at no cost and are listed below. In addition to the data files, the survey questionnaires, codebooks, and accompanying information (if available) are also posted for download. Note that some survey items have been suppressed to maintain panelist confidentiality. Restricted versions of these files may be available for purchase that include questions suppressed in the deidentified versions (see Restricted Data). The deidentified questionnaires indicate which items have been suppressed.
                </p>
              </React.Fragment>
            )}
            <SurveyView trackingId={trackingId} />
          </div>
        </Grid.Column>
      </Grid>
    </Container>
  )
}

export default Deidentified