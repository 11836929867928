import React, { Component } from 'react';
import { Grid, Header, Label, List, Segment, Table } from 'semantic-ui-react'
import moment from 'moment'

const SurveyListItem = (props) => (
  <Table.Row
    onClick={() => props.showSurveyModal(props.survey._id.$oid)}
  >
    <Table.Cell>
      <div className="survey-list-item__header">
        <Header as='h4'>
          {props.survey.name}
        </Header>
      </div>

      <div className='survey-list-item__description'>
        {props.survey.description}
      </div>

      <div className="survey-list-item__meta">
        {`Updated ${moment(props.survey.updated_at).format('MMM D, YYYY')}`}
      </div>

      <div className="survey-list-item__topics">
        <List>
          <List.Item>
            <List.Icon name='book' />
            <List.Content>
              {props.survey.topics.join(', ')}
            </List.Content>
          </List.Item>
        </List>
      </div>

      <div className="survey-list-item__keywords">
        <List>
          <List.Item>
            <List.Icon name='tag' />
            <List.Content>
              {props.survey.keywords.join(', ')}
            </List.Content>
          </List.Item>
        </List>
      </div>
    </Table.Cell>
  </Table.Row>
)

export default SurveyListItem;
