import React, { Component } from 'react';
import { Dropdown, Icon, Label, Table } from 'semantic-ui-react'
import moment from 'moment'

const SurveyRow = (props) => (
  <Table.Row>
    <Table.Cell width={7}>
      <strong>{props.survey.name}</strong>
    </Table.Cell>
    <Table.Cell width={5}>
      {`${moment(props.survey.updated_at).format('MMM D, YYYY')}`}
    </Table.Cell>
    <Table.Cell width={2}>
      <Label color={props.survey.active ? 'green' : 'grey'}>
        {props.survey.active ? 'Active' : 'Inactive'}
      </Label>
    </Table.Cell>
    <Table.Cell width={1} textAlign='center'>
      <Dropdown
        inline
        icon='ellipsis horizontal'
        className='link item'
      >
        <Dropdown.Menu>
          <Dropdown.Item
            icon='info circle'
            onClick={() => props.showSurveyModal(props.survey._id.$oid)}
            text='Details'
          />
          <Dropdown.Item
            icon='chart bar'
            onClick={() => props.showMetricsModal(props.survey._id.$oid)}
            text='Metrics'
          />
          <Dropdown.Item
            icon='edit'
            onClick={() => props.showEditSurveyModal(props.survey._id.$oid)}
            text='Edit'
          />
          <Dropdown.Divider />
          <Dropdown.Item
            icon='cancel'
            onClick={() => props.showDeleteSurveyModal(props.survey._id.$oid)}
            text='Delete'
          />
        </Dropdown.Menu>
      </Dropdown>
    </Table.Cell>
  </Table.Row>
)

export default SurveyRow;
