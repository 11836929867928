import React, { Component } from 'react';
import { Segment, Table } from 'semantic-ui-react'

import SurveyListItem from './survey_list_item'

class SurveyList extends Component {
  render() {
    return (
      <Table selectable>
        <Table.Body>
          {this.props.surveys.map((survey, idx) => {
            return(
              <SurveyListItem
                key={`survey-list-item-${survey._id.$oid}-${idx}`}
                showSurveyModal={this.props.showSurveyModal}
                survey={survey}
              />
            )
          })}
        </Table.Body>
      </Table>
    )
  }

}

export default SurveyList;
