import React, { Component } from 'react'
import get from 'lodash/get'
import { Button, Dropdown, Header, Icon, Label, List, Modal, Segment, Table, Input, Form, Message } from 'semantic-ui-react'

import moment from 'moment'

import { getOrgType, getIntendedUse } from '../constants'

const accessOptions = [
  {
    key: 'grant',
    text: 'Grant Access',
    value: 'grant',
  },
  {
    key: 'deny',
    text: 'Deny Access',
    value: 'deny',
  }
]

const denyReasons = [
  {
    key: 'Access to the AEP Data Portal is restricted to non-commercial researchers',
    text: 'Access to the AEP Data Portal is restricted to non-commercial researchers',
    value: 'Access to the AEP Data Portal is restricted to non-commercial researchers',
  },
  {
    key: 'Email address is not institutionally affiliated',
    text: 'Email address is not institutionally affiliated',
    value: 'Email address is not institutionally affiliated',
  }
]

class UserModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accessState: '',
      accessDeniedReason: '',
      email: props.data.email,
      emailLocked: true,
    }
  }

  handleAccessChange = (e, { value }) => {
    this.setState({
      accessState: value,
      accessDeniedReason: ''
    })
  }

  handleOnDenyChange = (e, { value }) => {
    this.setState({
      accessDeniedReason: value
    })
  }

  renderAccessOps() {
    const user = this.props.data
    const { accessState, accessDeniedReason } = this.state

    const denyOperation = (
      <div className='user-operation deny-operation'>
        <label>You must select a reason why this user is not being granted access</label>
        <Dropdown
          placeholder='Indicate reason for denying access to user'
          fluid
          selection
          options={denyReasons}
          onChange={this.handleOnDenyChange}
        />

        <Button
          icon
          labelPosition='right'
          negative
          disabled={this.state.accessDeniedReason === ''}
          onClick={() => this.props.handleAccess(user._id.$oid, 'deny', accessDeniedReason)}>
          <Icon name='ban' />
          Deny Access
        </Button>
      </div>
    )

    const grantOperation = (
      <div className='user-operation grant-operation'>
        <Button icon labelPosition='left' positive onClick={() => this.props.handleAccess(user._id.$oid, 'grant')}>
          <Icon name='thumbs up outline' />
          Grant Access
        </Button>
      </div>
    )

    if (!user.access_granted && !user.access_denied) {
      return (
        <Segment id='user-grant-deny'>
          <label>Select an option for this user's access</label>
          <Dropdown
            placeholder='Grant or Deny Access'
            fluid
            selection
            options={accessOptions}
            onChange={this.handleAccessChange}
          />
        { accessState === 'grant' ? grantOperation : null }
        { accessState === 'deny' ? denyOperation : null }
        </Segment>
      )
    } else if (user.access_denied) {
      return (
        <List horizontal>
          <List.Item>
            <Button icon labelPosition='left' positive onClick={() => this.props.handleAccess(user._id.$oid, 'grant')}>
              <Icon name='thumbs up outline' />
              Grant Access
            </Button>
          </List.Item>
        </List>
      )
    } else if (user.access_granted) {
      return (
        <List horizontal>
          <List.Item>
            <Button icon labelPosition='left' negative onClick={() => this.props.handleAccess(user._id.$oid, 'deny')}>
              <Icon name='ban' />
              Revoke Access
            </Button>
          </List.Item>
        </List>
      )
    } else {
      console.log('Invalid access state...')
    }
  }

  renderAccessState() {
    const user = this.props.data
    if (user.access_granted) {
      return (
        <Table.Row verticalAlign="top">
          <Table.Cell><strong>Date Access Granted:</strong></Table.Cell>
          <Table.Cell>{moment(user.access_granted).format('MMM D, YYYY')}</Table.Cell>
        </Table.Row>
      )
    } else if (user.access_denied) {
      return (
        <Table.Row verticalAlign="top">
          <Table.Cell><strong>Date Access Denied:</strong></Table.Cell>
          <Table.Cell>{moment(user.access_denied).format('MMM D, YYYY')}</Table.Cell>
        </Table.Row>
      )
    }
  }

  renderAccessLabel() {
    const user = this.props.data

    if (user.access_granted) {
      return (
        <Label horizontal color='green' size='large'>Access Granted</Label>
      )
    } else if (user.access_denied) {
      return (
        <Label horizontal color='red' size='large'>Access Denied</Label>
      )
    } else {
      return (
        <Label horizontal size='large'>Registered</Label>
      )
    }
  }

  handleEmailChange = (e, {value}) => {
    this.setState({
      email: value
    })
  }

  toggleEmailLock = () => {
    const {emailLocked} = this.state
    if (!emailLocked == true) {
      this.setState({
        email: this.props.data.email
      })
    }

    this.setState({
      emailLocked: !emailLocked
    })
  }

  onUpdateEmail = () => {
    const {_id} = this.props.data
    const {email} = this.state
    this.props.handleEmailUpdate(_id.$oid, email)
    this.setState({
      emailLocked: true
    })
  }

  render() {
    const user = this.props.data
    const {email, emailLocked} = this.state

    const unconfirmedEmail = get(user, 'unconfirmed_email', null)


    const address = user.address2 && user.address2.trim() == '' ? user.address : `${user.address}, ${user.address2}`

  return (
      <Modal id="user-modal" open={true} onClose={this.props.onClose}>
        <Modal.Header>
          {`${user.first_name} ${user.last_name}`}&nbsp;{this.renderAccessLabel()}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Header as='h4' dividing>Registration Information</Header>
            {unconfirmedEmail && (
              <Message info>
                This user's email has been changed and we are awaiting a confirmation response from their new email: <b>{unconfirmedEmail}</b>.
              </Message>
            )}
            <Form>
              <Form.Group>
                <Form.Field>
                  <Input
                    floated="left"
                    value={email}
                    disabled={emailLocked}
                    onChange={this.handleEmailChange}
                  />
                </Form.Field>
                {emailLocked && (
                  <Button
                    floated="right"
                    onClick={this.toggleEmailLock}
                  >
                      Modify email
                  </Button>
                )}
                {!emailLocked && (
                  <React.Fragment>
                    <Button
                      floated="right"
                      onClick={this.toggleEmailLock}
                    >
                        Cancel
                    </Button>
                    <Button
                      floated="right"
                      onClick={this.onUpdateEmail}
                      color="green"
                    >
                      Update email
                    </Button>
                  </React.Fragment>
                )}
              </Form.Group>
            </Form>

            <Table basic='very'>
              <Table.Body>
                <Table.Row verticalAlign="top">
                  <Table.Cell><strong>Date Registered:</strong></Table.Cell>
                  <Table.Cell>{moment(user.created_at).format('MMM D, YYYY')}</Table.Cell>
                </Table.Row>
                {this.renderAccessState()}
              </Table.Body>
            </Table>

            { this.renderAccessOps() }

            <Header as='h4' dividing>Contact Information</Header>
            <Table basic='very'>
              <Table.Body>
                <Table.Row verticalAlign="top">
                  <Table.Cell><strong>Organization:</strong></Table.Cell>
                  <Table.Cell>{user.organization ? user.organization : "N/A"}</Table.Cell>
                </Table.Row>

                <Table.Row verticalAlign="top">
                  <Table.Cell><strong>Department:</strong></Table.Cell>
                  <Table.Cell>{user.department ? user.department : "N/A"}</Table.Cell>
                </Table.Row>

                <Table.Row verticalAlign="top">
                  <Table.Cell><strong>Position:</strong></Table.Cell>
                  <Table.Cell>
                    {user.position ? user.position : "N/A"}
                    {user.position === 'Other'
                     ? <span><br />{user.other_position}</span>
                     : null
                    }
                  </Table.Cell>
                </Table.Row>

                <Table.Row verticalAlign="top">
                  <Table.Cell><strong>Organization Type:</strong></Table.Cell>
                  <Table.Cell>{getOrgType(user.org_type)}</Table.Cell>
                </Table.Row>

                <Table.Row verticalAlign="top">
                  <Table.Cell><strong>Intended Use:</strong></Table.Cell>
                  <Table.Cell>{getIntendedUse(user.intended_use)}</Table.Cell>
                </Table.Row>

                <Table.Row verticalAlign="top">
                  <Table.Cell>
                    <strong>Address:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {address}<br />
                    {`${user.city}, ${user.state} ${user.zip}`}
                  </Table.Cell>
                </Table.Row>
                <Table.Row verticalAlign="top">
                  <Table.Cell>
                    <strong>E-mail:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {user.email}
                  </Table.Cell>
                </Table.Row>

                <Table.Row verticalAlign="top">
                  <Table.Cell>
                    <strong>Receives Announcements:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {user.receive_announcments ? 'Yes' : 'No' }
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default UserModal
