import React, { useState } from 'react'
import Cookies from 'js-cookie'
import { Container, Icon, Message } from 'semantic-ui-react'

const ProfileReminder = () => {
  const [visible, toggleVisible] = useState(true)

  const dismissReminder = () => {
    // Remove the cookie for this session; won't be refreshed until
    // a login on action has been taken
    Cookies.remove('aep_profile')
    toggleVisible(false)
  }

  const profileCookie = Cookies.get('aep_profile')
  if (profileCookie === 'true') {
    return null
  }

  // Also could indicate that the profile reminder has been dismissed.
  const profileIncomplete = profileCookie && profileCookie !== 'true'

  if (visible && profileIncomplete) {
    return (
      <Message
        className="profile-reminder"
        color="yellow"
        style={{ borderRadius: "0px"}}
      >
        <Container>
          <Icon name="cancel" onClick={dismissReminder} style={{ cursor: 'pointer' }}/>
          Thanks for registering! To better serve you, <a href="/profile">please complete your profile</a>.
        </Container>
      </Message>
    )
  }
  return null
}

export default ProfileReminder