import axios from 'axios'
import React, { useEffect, useState, useCallback } from 'react'
import get from  'lodash/get'
import range from 'lodash/range'
import { Button, Modal, } from 'semantic-ui-react'
import some from 'lodash/some'
import InitialApplication from './initial_application'
import RestrictedApplicationAddon from './restricted_application_addon'
import RestrictedApplicationSubmit from './restricted_application_submit'
import { randomKeyString } from '../../../utils'
import { cvMimeTypes } from '../../../constants'

const applicationSteps = {
  APPLY: 'apply',
  ADDON: 'addon',
  SUBMIT: 'submit'
}

const wordCounts = {
  summary: 0,
  justification: 0,
  security_statement: 0,
}

const wordLimits = {
  summary: 300,
  justification: 300,
  security_statement: 300,
}



const errorTemplate = {
  summary: false,
  justification: false,
  security_statement: false,
}

const csrfToken = document.getElementsByName('csrf-token')[0].content

const RestrictedApplicationModal = ({ data, record, onClose, handleSubmit, loading}) => {
  const [applicationData, updateApplicationData] = useState({
    summary: '',
    justification: '',
    security_statement: '',
    colleagues: {},
    colleagueOrder: [],
  })
  const [currentStep, updateStep] = useState(applicationSteps.APPLY)
  const [cvFile, setCvFile] = useState(null)
  const [uploadedCv, setUploadedCv] = useState(null)
  const [cvKey, setCvKey] = useState('')
  const [isUploading, toggleUploading] = useState(false)
  const [cvError, setCvError] = useState(null)
  const [loadingCv, toggleLoadingCv] = useState(true)
  const [applicationErrors, setApplicationErrors] = useState(errorTemplate)
  const [applicationCounts, setApplicationCounts] = useState(wordCounts)

  if(!record) {
    return null
  }

  const getUploadedCv = useCallback(async () => {
    try {
      const response = await axios.get('/api/v1/cv')
      setUploadedCv(response.data)
    } catch (err) {
      console.error('err', err)
      console.error('err', err.stack)
    } finally {
      toggleLoadingCv(false)
    }
  }, [uploadedCv])

  useEffect(() => {
    getUploadedCv()
    setCvKey(randomKeyString())

    if (data) {
      const {
        colleagues,
        justification,
        security_statement,
        summary
      } = data
      const colleagueOrder = []
      const colleaguePlaceholder = {}
      colleagues.forEach((colleague) => {
        const colleagueKey = randomKeyString()
        colleaguePlaceholder[colleagueKey] = colleague
        colleagueOrder.push(colleagueKey)
      })
      updateApplicationData({
        colleagues: colleaguePlaceholder,
        colleagueOrder,
        justification,
        security_statement,
        summary,
      })

      const updatedDataCounts = {...wordCounts}
      Object.keys(wordCounts).forEach((key) => {
        let dataValue = get(data, key, '')
        if (dataValue === null) {
          dataValue = ''
        }
        const currentValue = getWordCount(dataValue)
        updatedDataCounts[key] = currentValue.length
      })
      setApplicationCounts(updatedDataCounts)
    }
  }, [])

  const addColleague = (e) => {
    const updatedData = {...applicationData}
    const { colleagues, colleagueOrder } = updatedData
    const colleagueKey = randomKeyString()
    colleagues[colleagueKey] = ''
    colleagueOrder.push(colleagueKey)
    updateApplicationData({
      ...updatedData,
    })
  }

  const updateColleague = (value, key) => {
    const updatedData = {...applicationData}
    const { colleagues } = updatedData
    colleagues[key] = value
    updateApplicationData({
      ...updatedData,
      colleagues
    })
  }

  const removeColleague = (key) => {
    const updatedData = {...applicationData}
    const { colleagues, colleagueOrder } = updatedData
    const updatedOrder = colleagueOrder.filter((id) => {
      return id !== key
    })
    delete colleagues[key]
    updateApplicationData({
      ...updatedData,
      colleagueOrder: updatedOrder,
      colleagues,
    })
  }

  const selectCV = (e) => {
    if (e.target.files.length === 0) {
      setCvFile(null)
      setCvKey(randomKeyString())
      return
    }

    if (cvMimeTypes.indexOf(e.target.files[0].type) === -1) {
      setCvError('Only PDF or Word Documents will be accepted.')
    } else {
      setCvError(null)
    }

    setCvFile(e.target.files[0])
  }

  const uploadCv = async () => {
    try {
      toggleUploading(true)
      const formData = new FormData()
      formData.append('cv[files][]', cvFile)
      const response = await axios.post('/api/v1/cv',
        formData, {
          headers: {
            'X-CSRF-TOKEN': csrfToken,
          }
      })
      setUploadedCv(response.data)
      setCvFile(null)
      setCvKey(randomKeyString())
    } catch (err) {
      console.error('err', err)
      console.error('err.stack', err.stack)
    } finally {
      toggleUploading(false)
    }
  }

  const resetCV = () => {
    setCvFile(null)
    setCvKey(randomKeyString())
    setCvError(null)
  }


  const submitApplication = async (submitted) => {
    const { colleagues, colleagueOrder } = applicationData
    const colleagueArr = colleagueOrder.filter((key) => {
      return colleagues[key].trim() !== ''
    }).map((key) => {
      return colleagues[key]
    })
    const formData = {
      restricted_application: {
        ...applicationData,
        restricted_data_id: record._id.$oid,
        colleagues: colleagueArr,
        submitted,
      }
    }
    let updateId = data !== null ? data._id.$oid : null
    handleSubmit(formData, updateId)
  }

  const getWordCount = (value) => {
    const wordRegex = /[^a-z\d\s]+/gi;
    const wordCounter = (value.trim()//remove whitespace
                      .replace(/[\W]+/g, ' ')
                      .replace(/([a-z]+)\b[.,]/g, '')//remove commas & fullstops
                      .replace(wordRegex, '')
                      .split(' ')// split words into array elements
                      .filter(function(x){// remove empty array elements
                        return x !== ''
                      }) || [])
    return wordCounter
  }

  const handleUpdateData = (field, value) => {
    const updatedData = {
      ...applicationData,
      [field]: value
    }
    const wordCounter = getWordCount(value)
    setApplicationCounts({
      ...applicationCounts,
      [field]: wordCounter.length,
    })
    if (wordCounter.length > wordLimits[field]) {
      setApplicationErrors({
        ...applicationErrors,
        [field]: true,
      })
    } else {
      setApplicationErrors({
        ...applicationErrors,
        [field]: false,
      })
    }

    updateApplicationData(updatedData)
  }

  const handleBack = () => {
    if (currentStep === applicationSteps.ADDON) {
      updateStep(applicationSteps.APPLY)
    } else {
      updateStep(applicationSteps.ADDON)
    }
  }

  const handleAdvance = () => {
    if (currentStep === applicationSteps.APPLY) {
      updateStep(applicationSteps.ADDON)
    } else if (currentStep === applicationSteps.ADDON) {
      updateStep(applicationSteps.SUBMIT)
    }else {
      submitApplication(true)
    }
  }

  let DisplayStep, advanceText
  switch (currentStep) {
    case applicationSteps.APPLY:
      DisplayStep = InitialApplication
      advanceText = 'Next'
      break;
    case applicationSteps.ADDON:
      DisplayStep = RestrictedApplicationAddon
      advanceText = 'Review'
      break;
    case applicationSteps.SUBMIT:
      DisplayStep = RestrictedApplicationSubmit
      advanceText = 'Submit'
      break;
  }

  const checkErrors = (obj) => {
    return obj
  }

  let noAdvance
  if (currentStep === applicationSteps.APPLY) {
    noAdvance = applicationData.summary.trim() === ''
                  || uploadedCv === null
                  || cvError !== null
                  || some(applicationErrors, checkErrors)
  } else {
    noAdvance = applicationData.summary.trim() === ''
                    || applicationData.justification.trim() === ''
                    || applicationData.security_statement.trim() === ''
                    || some(applicationErrors, checkErrors)
  }

  return (
    <Modal
      open={true}
      closeOnDimmerClick={false}
      onClose={onClose}
      size='small'
      className="restricted-modal"
      centered={false}
    >
      <Modal.Header>Application for {record.name}</Modal.Header>
      <Modal.Content>
        <div className="application">
          { currentStep === applicationSteps.ADDON
            ? (
                <DisplayStep
                  data={applicationData}
                  handleChange={handleUpdateData}
                  errors={applicationErrors}
                  wordLimits={wordLimits}
                  wordCounts={applicationCounts}
                />
              )
            : (
              <DisplayStep
                data={applicationData}
                onSelect={selectCV}
                onUpload={uploadCv}
                selectedCv={cvFile}
                handleChange={handleUpdateData}
                uploadedCv={uploadedCv}
                onReset={resetCV}
                cvKey={cvKey}
                isUploading={isUploading}
                cvError={cvError}
                isLoading={loadingCv}
                errors={applicationErrors}
                wordLimits={wordLimits}
                wordCounts={applicationCounts}
                addColleague={addColleague}
                updateColleague={updateColleague}
                removeColleague={removeColleague}
              />
            )
          }

        </div>
      </Modal.Content>
      <Modal.Actions className="application-actions">
        <div className="save-button">
          <Button
            color="green"
            disabled={noAdvance || loading }
            onClick={(e) => submitApplication(false)}
          >
            Save
          </Button>
        </div>
        <div className="advance-actions">
          <Button basic disabled={loading} onClick={onClose}>Cancel</Button>
          { currentStep === applicationSteps.APPLY
            ? null
            : (
            <Button
              onClick={handleBack}
              className="previous"
              disabled={applicationErrors.justification || applicationErrors.security_statement || loading }
            >
              Back
            </Button>
          )}
          <Button
            onClick={handleAdvance}
            className="next"
            disabled={noAdvance || loading}
          >
            {advanceText}
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default RestrictedApplicationModal