import React from 'react'
import { Button, Icon, Label, Table, Header, } from 'semantic-ui-react'
import moment from 'moment'
import lowerCase from 'lodash/lowerCase'
import { applicationStatus } from '../../../constants'

const RestrictedData = ({
  data,
  existingApplications,
  openApplication,
  openModal,
  profileComplete,
  applicationData,
}) => {
  const { surveys } = data

  return (
    <div className="restricted-data" style={{ paddingTop: '1.5rem' }}>
    <Table stackable singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={4}>Name</Table.HeaderCell>
          <Table.HeaderCell width={4}>Last Updated</Table.HeaderCell>
          <Table.HeaderCell width={8}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {(!surveys || surveys.length == 0) && (
          <Table.Row>
            <Table.Cell colSpan={3}>
              No restricted data available.
            </Table.Cell>
          </Table.Row>
        )}
        {surveys.length > 0 &&  surveys.map((record) => {
          const data = existingApplications[record._id.$oid]
          return (
            <Table.Row key={`restricted-record-${record._id.$oid}`}>
              <Table.Cell>{record.name}</Table.Cell>
              <Table.Cell>{moment(record.updated_at).format('MM/DD/YYYY')}</Table.Cell>
              <Table.Cell>
                <Button icon onClick={() => openModal(record._id.$oid)}>
                  <Icon name="eye" />
                  &nbsp;
                  Information
                </Button>
                { existingApplications[record._id.$oid]
                  ? (
                    <Button
                      disabled={applicationData[record._id.$oid].submitted}
                      className={`status ${lowerCase(applicationStatus[applicationData[record._id.$oid].status])}`}
                      onClick={() => openApplication(record._id.$oid)}
                    >
                      {applicationData[record._id.$oid].submitted
                        ?
                        applicationStatus[applicationData[record._id.$oid].status]
                        : 'Not submitted'
                      }
                    </Button>
                  )
                  : (
                    <Button
                      color="green"
                      disabled={!profileComplete}
                      onClick={() => openApplication(record._id.$oid)}
                    >
                      Restricted Data Application
                    </Button>
                  )
                }
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
    </div>
  )
}

export default RestrictedData