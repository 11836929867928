import React, { Component } from 'react'
import axios from 'axios'
import { Button, Container, Form, Header, Icon, Input, Pagination, Segment } from 'semantic-ui-react'

import AdminsTable from './admins_table'

import { randomKeyString } from '../utils'

let searchTimeout = null

class Admins extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activePage: 1,
      isLoading: true,
      query: '',
      admins: [],
      adminCount: null,
      key: null,
    }

    this.clearSearch = this.clearSearch.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
  }

  getAdmins(activePage) {
    const setPage = activePage === undefined ? 1 : activePage
    const usersUrl = activePage === undefined ? '/api/v1/admins.json' : `/api/v1/admins.json?page=${activePage}`

    const newKey = randomKeyString(7)
    axios.get(usersUrl)
      .then((response) => {
        this.setState({
          activePage: setPage,
          isLoading: false,
          admins: response.data.admins,
          adminCount: response.data.admin_count,
          key: `admins-page-${newKey}`
        })
      })
  }

  searchAdmins(activePage) {
    const { query } = this.state
    const setPage = activePage === undefined ? 1 : activePage

    if (query === '') {
      this.getAdmins()
      return
    }
    const newKey = randomKeyString(7)

    axios.get(`/api/v1/admins/search?query=${query}`)
      .then((response) => {
        this.setState({
          activePage: setPage,
          isLoading: false,
          admins: response.data.admins,
          adminCount: response.data.admin_count,
          key: `admins-search-page-${newKey}`
        })
      })
  }

  componentDidMount() {
    this.getAdmins()
  }

  handlePageChange(e, { activePage }) {
    this.setState({
      isLoading: true
    }, () => {
      this.getAdmins(activePage)
    })
  }

  handleSearchChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  clearSearch(e) {
    this.setState({
      query: '',
    }, () => this.getAdmins())
  }

  handleSearchKeyPress = (e) => {
    if (e.charCode == 13) {
      this.searchAdmins()
    }
  }

  showSearch() {
    const { activePage, isLoading, query, users, userCount } = this.state

    return (
      <Input
        type="text"
        onChange={this.handleSearchChange}
        onKeyPress={this.handleSearchKeyPress}
        placeholder='Search for an admin by name or email'
        action={{ icon: 'search', onClick: this.searchAdmins }}
        fluid
      />
    )
  }

  showPagination() {
    const { activePage, isLoading, admins, adminCount } = this.state
    if (admins.length > 0) {
      return (
        <div className='aep-admin__pagination'>
          <Pagination
            secondary
            activePage={activePage}
            totalPages={Math.ceil(adminCount / 5)}
            onPageChange={this.handlePageChange}
          />
        </div>
      )
    }
  }

  render() {
    return(
      <div id="admins" className='aep-admin__container'>
        <Container>
        <div className='aep-admin__header'>
          <a href="/admin/new">
            <Button
              icon
              labelPosition='left'
              floated='right'
              style={{ 'marginBottom': '1em' }}
            >
                <Icon name='add user' />
                Create New Admin
            </Button>
            <Header as='h1'>Admins</Header>
          </a>
        </div>

        <div className='aep-admin__search'>
          <Segment>
            {this.showSearch()}
          </Segment>
        </div>

        <Segment textAlign='center'>
          { this.state.isLoading ? <i className="fas fa-circle-notch fa-spin"></i> : <AdminsTable admins={this.state.admins} key={this.state.key}/> }

          {this.showPagination()}
        </Segment>
        </Container>
      </div>
    )
  }
}

export default Admins
