import React, { Component } from 'react'
import { Container } from 'semantic-ui-react'
import UserModal from './user_modal'
import CreateUserModal from './create_user_modal'
import CreateSurveyModal from './surveys/create_survey_modal'
import MetricsSurveyModal from './surveys/metrics_survey_modal'
import SurveyModal from './survey_modal'
import TermsModal from './terms_modal'
import DeleteSurveyModal from './surveys/delete_survey_modal'
import EditSurveyModal from './surveys/edit_survey_modal'

class ModalContainer extends Component {
  render() {
    const { type, data, onClose, handleEvent } = this.props
    let modal
    console.log('this.props', this.props)
    switch (type) {
      case 'UserModal':
        modal = <UserModal
                  data={data}
                  onClose={onClose}
                  {...this.props} />
        break
      case 'CreateUserModal':
        modal = <CreateUserModal
                  {...this.props} />
        break
      case 'CreateSurveyModal':
        modal = <CreateSurveyModal
                  {...this.props} />
        break
      case 'MetricsSurveyModal':
        modal = <MetricsSurveyModal
                  {...this.props} />
        break
      case 'SurveyModal':
        modal = <SurveyModal
                  {...this.props} />
        break
      case 'TermsModal':
        modal = <TermsModal
                  {...this.props} />
        break
      case 'DeleteSurveyModal':
        modal = <DeleteSurveyModal
                  {...this.props} />
        break
      case 'EditSurveyModal':
        modal = <EditSurveyModal
                  {...this.props} />
        break
      default:
        modal = null
    }

    if (data) {
      return (
        <Container id="modal-container">
          {modal}
        </Container>
      )
    } else {
      return null
    }

  }
}

export default ModalContainer
