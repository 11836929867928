import React, { Component } from 'react'
import { Button, Divider, Header, List, Modal } from 'semantic-ui-react'

class TermsModal extends Component {
  constructor(props) {
    super(props)
  }

  closeWithTerms = (termsAccepted) => {
    this.props.onClose(termsAccepted)
  }

  render() {
    return (
      <Modal id='terms-modal' open={true} onClose={this.props.onClose}>
        <Modal.Header>AEP Data Portal Terms and Conditions</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>Thank you for your interest in the American Educator Panels (AEP) public use data files. These data are provided at no-cost to the education research community and may be used for research purposes only. Your submitted data request form will be reviewed. If approved, you will receive access to the data portal.</p>

            <p>While AEP staff make every effort to ensure the accuracy of the data and the documentation hosted on the AEP data poral, errors may be present. The AEP will make reasonable efforts via email and/or announcements on the AEP website to notify registered users of changes to data files and/or documentation.
            Please read the terms of use below carefully. You must indicate your compliance with these terms by clicking the "I Agree" button below to proceed. Otherwise, please click "I Do Not Agree" to return to the AEP home page.</p>

            <List bulleted>
              <List.Item>To use these datasets solely for research or statistical purposes and not for investigation of specific survey respondents.</List.Item>
              <List.Item>Make no attempts to identify study participants. To make no use of the identity of any study participant(s) discovered intentionally or inadvertently, and to advise AEP of any such discovery (<a href='mailto:aepdata@rand.org'>aepdata@rand.org</a>)</List.Item>
              <List.Item>To cite AEP data and documentation in your work that makes use of the data and documentation. Authors of publications based on AEP data should a digital copy of their publication to the AEP (<a href='mailto:aepdata@rand.org'>aepdata@rand.org</a>).</List.Item>
              <List.Item>You acknowledge that RAND, the original collector of the data, as well as the relevant funding agency/agencies bear no responsibility for use of the data or for interpretations or inferences based upon such uses.</List.Item>
              <List.Item>Not to transfer AEP public use files to any third party other than staff or students for whom you are directly responsible. For permission to transfer AEP files to a third party, please contact us <a href='mailto:aepdata@rand.org'>aepdata@rand.org</a></List.Item>
              <List.Item>To certify the destruction of any AEP files, as well as any data derived from AEP data, when requested to do so by RAND.</List.Item>
              <List.Item>Report immediately to the AEP at <a href='mailto:aepdata@rand.org'>aepdata@rand.org</a> any discovery of flaws or errors in the data or documentation.</List.Item>
            </List>
          </Modal.Description>
        </Modal.Content>
        <Divider />
        <div className='modal-footer'>
          <Button positive onClick={() => this.closeWithTerms(true)}>I Agree</Button>
          <Button negative onClick={() => this.closeWithTerms(false)}>I Do Not Agree</Button>
        </div>
      </Modal>
    )
  }

}

export default TermsModal
