import React from 'react'
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Loader,
  Message,
  Popup,
  TextArea,
} from 'semantic-ui-react'

import { ethnicityOptions } from '../../../constants'
const getBooleanOptions = (key) => {
  return [
    { key: `${key}-yes`, value: true, text: 'Yes' },
    { key: `${key}-no`, value: false, text: 'No' },
  ]
}

function ApplicationForm({
  applicationData,
  handleChange,
  selectedCv,
  uploadedCv,
  onUpload,
  onReset,
  cvKey,
  cvError,
  isLoading,
  isUploading,
  onSelect,
  wordLimits,
  wordCounts,
}) {
  const specifyOther = applicationData.ethnicity.indexOf('Other') !== -1

  return (
    <React.Fragment>
      <p>
        Thank you for your interest in the AEP Scholarship. If you have not done so already, please complete your profile on the AEP Portal in addition to the below form as part of your application.
      </p>
      <p>
        <b>Items marked with a <span style={{color: 'red'}}>*</span> are required.</b>
      </p>
      <Form className="scholarship-form">
        <Form.Field>
          <label>1.	Ethnicity</label>
          <Dropdown
            className="field-input"
            selectOnBlur={false}
            selection
            multiple
            options={ethnicityOptions}
            onChange={(e, { value }) => handleChange('ethnicity', value)}
            value={applicationData.ethnicity}
            placeholder="Please choose all that apply"
          />
          {specifyOther && (
            <div
              className="specify-other"
              style={{padding: '1rem 0'}}
            >
              <Form.Input
                ype="text"
                onChange={(e, { value }) =>
                  handleChange('ethnicity_other', value)}
                value={applicationData.ethnicity_other}
                placeholder="If 'Other', please specify."
              />
            </div>
          )}
        </Form.Field>
        <Form.Field
          required
          error={wordCounts.find_out > wordLimits.find_out}
        >
          <label>
            2. How did you find out about this scholarship? Include the name of the organization or listserv if applicable.
          </label>
          <TextArea
            onChange={(e, { value }) => handleChange('find_out', value)}
            value={applicationData.find_out}
          />
          <div className="word-limit">
            {wordCounts.find_out} / {wordLimits.find_out} words
          </div>
        </Form.Field>
        <Form.Field
          required
          error={wordCounts.fit > wordLimits.fit}
        >
          <label>
            3. What makes you a good fit for this scholarship? Please keep in mind the eligibility criteria.
          </label>
          <TextArea
            onChange={(e, { value }) => handleChange('fit', value)}
            value={applicationData.fit}
          />
          <div className="word-limit">
            {wordCounts.fit} / {wordLimits.fit} words
          </div>
        </Form.Field>
        <Form.Field required={!uploadedCv}>
          <label>4. CV</label>
          { isLoading && (
            <Loader active />
          )}
          {!isLoading && uploadedCv && (
            <div className="uploaded-cv">
              <Icon name='file' />
              <span className="cv filename">
                {uploadedCv.filename}
              </span>
              <span className="cv upload-date">
                {uploadedCv.upload_date}
              </span>
            </div>
          )}
          {cvError && (
            <Message color="red">
              <p>{cvError}</p>
            </Message>
          )}
          <input
            type="file"
            onChange={(e) => onSelect(e)}
            key={cvKey}
          />
          {uploadedCv && !selectedCv && (
            <div className="uploaded-cv">
              <span>A CV is already attached to your profile. If you want to update an existing CV, please upload it here.</span>
            </div>
          )}
          {selectedCv && (
            <div className="upload-op" style={{margin: '1rem 0'}}>
              <Button onClick={onUpload} disabled={isUploading || cvError !== null}>
                Upload CV
              </Button>
              <Button onClick={onReset}>
                Reset
              </Button>
            </div>
          )}
        </Form.Field>
        <Form.Field required>
          <label>
            5. Have you previously analyzed micro-level data files, e.g. individual- or teacher-level survey data?
          </label>
          <Dropdown
            selectOnBlur={false}
            selection
            options={getBooleanOptions('analyzed-micro')}
            onChange={
              (e, { value }) => handleChange('analyzed_micro_level', value)}
            value={applicationData.analyzed_micro_level}
            placeholder="Please select yes or no"
          />
        </Form.Field>
        <Form.Field
          required
          error={wordCounts.preferred_stat_platform > wordLimits.preferred_stat_platform}
        >
          <label>6. Preferred statistical package platform:</label>
          <TextArea
            onChange={(e, { value }) =>
              handleChange('preferred_stat_platform', value)}
              value={applicationData.preferred_stat_platform}
          />
          <div className="word-limit">
            {wordCounts.preferred_stat_platform} / {wordLimits.preferred_stat_platform} words
          </div>
        </Form.Field>
        <Form.Field
          required
          error={wordCounts.summary > wordLimits.summary}
        >
          <label>7. Summary of research: Please be specific and include the data you plan to use, the hypotheses you plan to examine and key variables for your analysis.</label>
          <TextArea
            onChange={(e, { value }) => handleChange('summary', value)}
            value={applicationData.summary}
          />
          <div className="word-limit">
            {wordCounts.summary} / {wordLimits.summary} words
          </div>
        </Form.Field>
        <Form.Field
          required
          error={wordCounts.data_interest > wordLimits.data_interest}
        >
          <label>8.	Data file/s of interest</label>
          <TextArea
            onChange={(e, { value }) => handleChange('data_interest', value)}
            value={applicationData.data_interest}
          />
          <div className="word-limit">
            {wordCounts.data_interest} / {wordLimits.data_interest} words
          </div>
        </Form.Field>
        <Form.Field required>
          <label>
            9. Do you anticipate needing access to restricted data?
            &nbsp;
            <Popup
              content="Restricted data refers to detailed data sets that include variables suppressed from the deidentified data sets and additional variables about schools, et cetera."
              trigger={<Icon name="circle question" />}
            />
          </label>
          <Dropdown
            selectOnBlur={false}
            selection
            options={getBooleanOptions('need-restricted-data')}
            onChange={(e, { value }) =>
              handleChange('access_to_restricted_data', value)}
            value={applicationData.access_to_restricted_data}
            placeholder="Please select yes or no"
          />
        </Form.Field>
      </Form>
    </React.Fragment>
  )
}

export default ApplicationForm